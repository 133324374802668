//& react & mui imports =======================================================================
import React from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

//& hook imports ==============================================================================
import { useAuth } from "../../contexts/AuthProvider.js";

//& component imports =========================================================================
import BaseButton from "../BaseControls/BaseButton.jsx";

//& component =================================================================================
const Login = () => {
  const { handleLoginInput, handleLoginSubmit, loginError } = useAuth();
  const navigate = useNavigate();

  //& rendering ===============================================================================
  return (
    <div className="login flexRow justifyCenter alignCenter">
      <div className="bubble flexColumn gapSmall alignCenter login-form">
        <font size="4">
          <b>Login</b>
        </font>
        <form
          className="flexColumn alignCenter gapSmall"
          onSubmit={handleLoginSubmit}
        >
          <TextField
            id="user-email"
            name="email"
            aria-describedby="user-email"
            aria-invalid="false"
            label="Email"
            size="small"
            variant="outlined"
            onChange={handleLoginInput}
            autoComplete="email"
          />
          <TextField
            id="password"
            name="password"
            type="password"
            aria-describedby="user-password"
            aria-invalid="false"
            label="Password"
            size="small"
            variant="outlined"
            onChange={handleLoginInput}
            autoComplete="current-password"
          />

          {loginError && (
            <Alert severity="error" className="login-error">
              {loginError}
            </Alert>
          )}

          <BaseButton
            className="login-button"
            type="submit"
            color="primary"
            caption="Anmelden"
            width="8rem"
          />
        </form>
        <BaseButton
          className="login-button"
          onClick={() => {
            navigate("/");
          }}
          caption="zur Hauptseite"
          width="8rem"
        />
      </div>
    </div>
  );
};

export default Login;
