//& react imports =============================================================================
import React, { createContext, useEffect, useState } from "react";

//& hook imports ===============================================================================
import { useWindowSize } from "../hooks/useWindowSize.js";

//& logic imports ==============================================================================
import props from "../logic/props.js";

//& resource imports ===========================================================================
import resources from "../resources.js";

//& library imports ===========================================================================
import axios from "axios";

const FrontendContext = createContext({});

export const FrontendProvider = ({ children }) => {
  const [activeMenuItem, activeMenuItemSet] = useState("start");
  const [frontendGigs, frontendGigsSet] = useState([]);
  const [isMobileMenuOpen, isMobileMenuOpenSet] = useState(false);
  const [message, messageSet] = useState("");
  const [modalData, modalDataSet] = useState(null);
  const [newsEntries, newsEntriesSet] = useState(null);
  const [newsFiltered, newsFilteredSet] = useState(null);
  const [settings, settingsSet] = useState(null);
  const [windowWidth, windowHeight] = useWindowSize();

  const RESOURCES = resources;

  const onImageClick = (_image) => {
    modalDataSet({
      type: "image",
      data: _image,
    });
  };

  const BASE = {
    activeMenuItem,
    activeMenuItemSet,
    modalData,
    modalDataSet,
    onDownloadClick: (_doc, _name) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = _doc;
      downloadLink.setAttribute("download", _name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    },
    onImageClick,
    settings,
    settingsSet,
    windowHeight,
    windowWidth,
  };

  const GIGS = {
    frontendGigs,
    frontendGigsSet,
  };

  const KONTAKT = {
    handleMessageChange: (_event) => {
      messageSet(_event.target.value);
    },
    mailSend: async () => {},
    message,
    messageSet,
  };

  const MENU = {
    activeMenuItem,
    activeMenuItemSet,
    onMenuItemClick: (_code) => {
      if (_code === activeMenuItem) {
        return;
      }

      activeMenuItemSet(_code);
    },
  };

  const MOBILE = {
    isMobileMenuOpen,
    isMobileMenuOpenSet,
    onClick: () => {
      isMobileMenuOpenSet(false);
    },
    onMenuClick: () => {
      isMobileMenuOpenSet((_prev) => {
        return !_prev;
      });
    },
    onMenuItemClick: (_code) => {
      activeMenuItemSet(_code);
      isMobileMenuOpenSet(false);
    },
  };

  const NEWS = {
    newsEntries,
    newsEntriesSet,
    newsFiltered,
    newsFilteredSet,
  };

  const startUp = async () => {
    axios
      .get(`${props.server.url}/frontendGigsGet`)
      .then((_response) => {
        frontendGigsSet(_response.data);
      })
      .catch((_error) => {});

    axios
      .get(`${props.server.url}/newsGet`)
      .then((_response) => {
        NEWS.newsEntriesSet(
          _response.data.sort(
            (a, b) =>
              new Date(b.date) - new Date(a.date) ||
              b.time.localeCompare(a.time)
          )
        );
      })
      .catch((_error) => {});

    axios
      .get(`${props.server.url}/settingsGet`)
      .then((_response) => {
        settingsSet(_response.data);
      })
      .catch((_error) => {});
  };

  useEffect(() => {
    startUp();
  }, []);

  useEffect(() => {
    if (!newsEntries || !settings) return;

    newsFilteredSet(
      newsEntries
        .sort(
          (a, b) =>
            new Date(b.date) - new Date(a.date) || b.time.localeCompare(a.time)
        )
        .filter((_entry) => _entry.visible)
        .slice(0, BASE.settings.newsDisplayCount)
    );
  }, [newsEntries, settings]);

  return (
    <FrontendContext.Provider
      value={{
        BASE,
        GIGS,
        KONTAKT,
        MENU,
        MOBILE,
        NEWS,
        RESOURCES,
      }}
    >
      {children}
    </FrontendContext.Provider>
  );
};

export default FrontendContext;
