//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useAdmin from "../../hooks/useAdmin.js";
import useDeviceDisplay from "../../hooks/useDeviceDisplay.js";

//& component imports =========================================================================
import News from "./News/News.jsx";
import Aufnahmen from "./Aufnahmen/Aufnahmen.jsx";
import Gigs from "./Gig/Gigs.jsx";

//& component =================================================================================
const Content = () => {
  const { MENU } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  const classNames = {
    wrapper:
      displayMode === "mobile"
        ? "flexColumn alignCenter gapSmall mobile-hsop-content "
        : "flexColumn alignCenter gapSmall content",
  };

  //& rendering ===============================================================================
  return (
    <div className={classNames.wrapper}>
      {MENU.activeMenuItem === "news" && <News />}
      {MENU.activeMenuItem === "gigs" && <Gigs />}
      {MENU.activeMenuItem === "aufnahmen" && <Aufnahmen />}
    </div>
  );
};

export default Content;
