//& react & mui imports =======================================================================
import React from "react"

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import BandMember from "../Band/BandMember.jsx";

//& component =================================================================================
const BandMobile = () => {
  const { RESOURCES} = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn alignCenter gapSmall">
        {RESOURCES.CREWMEMBERS.map((_member) => (
          <BandMember key={_member.name} member={_member} />
        ))}
    </div>
  )
};

export default BandMobile;
