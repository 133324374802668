//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../../hooks/useFrontend.js";

//& component imports =========================================================================
import NewsEntryMobile from "./NewsEntryMobile.jsx";

//& component =================================================================================
const NewsEntriesMobile = () => {
  const { BASE, NEWS } = useFrontend();

  //& rendering ===============================================================================
  if (!NEWS.newsFiltered || !BASE.settings) {
    return null;
  }

  return (
    <div className="flexColumn gapSmall bubble">
      <div className="textHeader textCenter">Neues und Bemerkenswertes</div>
      {NEWS.newsFiltered.map((_entry) => (
        <NewsEntryMobile key={_entry.id} entry={_entry} />
      ))}
    </div>
  );
};

export default NewsEntriesMobile;
