//& react & mui imports =======================================================================
import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const ImageModal = ({ image }) => {
  const { BASE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <Modal
      open={BASE.modalData && BASE.modalData.type === "image"}
      onClose={() => {
        BASE.modalDataSet(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="imageModal-box">
        <img
          src={BASE.modalData.data}
          alt="modal"
          className="imageModal-image"
        />
      </Box>
    </Modal>
  );
};

export default ImageModal;
