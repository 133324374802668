//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const Impressum = () => {
  const { RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="justifyCenter contentWidth bubble">
      <div
        dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.impressumHTML }}
      />
    </div>
  );
};

export default Impressum;
