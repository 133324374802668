//& react & mui imports =======================================================================
import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Alert from "@mui/material/Alert";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin";
import useDeviceDisplay from "../../../hooks/useDeviceDisplay";

//& component imports =========================================================================
import Gig from "./Gig";
import BaseButton from "../../BaseControls/BaseButton";
import ErrorAlert from "../ErrorAlert";

//& component =================================================================================
const GigArea = () => {
  const { BASE, GIGS } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  const classNames = {
    wrapper:
      displayMode === "mobile"
        ? "mobile-bubble"
        : "bubble flexColumn userSelectNone contentWidth",
  };

  //& useEffect ===============================================================================
  useEffect(() => {
    GIGS.startUp();
  }, []);

  //& rendering ===============================================================================
  if (GIGS.gigs === null) {
    return <>Lädt Auftritte...</>;
  }

  return (
    <div className={classNames.wrapper}>
      {BASE.error && <ErrorAlert />}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={GIGS.gigData !== null}
              value={GIGS.showPastGigs}
              onChange={(_event) => {
                GIGS.showPastGigsSet(_event.target.checked);
              }}
            />
          }
          label="Zeige vergangene Auftritte"
        />
      </FormGroup>

      <div className="gigArea-gigDivider" />

      <div className="flexRow alignCenter">
        {BASE.modifyMode === null && (
          <BaseButton
            disabled={BASE.modifyMode !== null}
            onClick={() => {
              GIGS.onClickCreate();
            }}
            caption="Neuen Auftritt anlegen"
            width="11rem"
          />
        )}

        {BASE.modifyMode === "create" && (
          <div className="gigArea-gig">
            <Gig gig={BASE.emptyEntities.gig} />
          </div>
        )}
      </div>

      {GIGS.gigError && (
        <Alert className="gig-error" severity="error">
          {GIGS.gigError}
        </Alert>
      )}

      <div className="gigArea-gigDivider" />

      {GIGS.gigs &&
        GIGS.gigs
          .filter((_gig) => {
            if (GIGS.showPastGigs) {
              return true;
            } else {
              const numericGigDate = parseInt(_gig.date.replace(/-/g, ""));
              const now = new Date();
              const nowTimezoned = new Date(
                now.getTime() + now.getTimezoneOffset() * -1 * 60 * 1000
              );
              const numericNow = parseInt(
                nowTimezoned.toISOString().split("T")[0].replace(/-/g, "")
              );

              return numericGigDate >= numericNow;
            }
          })
          .map((_gig, _index) => {
            return (
              <div key={_gig.id}>
                {_index > 0 && <div className="gigArea-gigDivider" />}
                <Gig gig={_gig} />
              </div>
            );
          })}
    </div>
  );
};

export default GigArea;
