//& react imports =============================================================================
import React, { createContext, useEffect, useState } from "react";

//& library imports ===========================================================================
import axios from "axios";

//& hook imports =============================================================================
import { useAuth } from "../contexts/AuthProvider";

//& logic imports =============================================================================
import props from "../logic/props";

const AdminContext = createContext({});

//& context provider ==========================================================================
export const AdminProvider = ({ children }) => {
  const { checkAuth, logout } = useAuth();

  const emptyEntities = {
    gig: {
      id: 0,
      type: "Öffentlich",
      date: "",
      startTime: "",
      endTime: "",
      title: "",
      anfahrt: "",
      info: "",
    },
    newsEntry: {
      title: "",
      content: [
        { type: "paragraph", children: [{ text: "Neuer News-Beitrag" }] },
      ],
      autor: "",
      date: new Date().toISOString().split("T")[0],
      time: new Date().toISOString().split("T")[1].split(".")[0],
      visible: true,
    },
  };

  const [activeMenuItem, activeMenuItemSet] = useState("news");
  const [audioIsLoadingName, audioIsLoadingNameSet] = useState(null);
  const [audioSources, audioSourcesSet] = useState({});
  const [aufnahmen, aufnahmenSet] = useState(null);
  const [aufnahmenSelectedFilter, aufnahmenSelectedFilterSet] = useState("");
  const [aufnahmenFilter, aufnahmenFilterSet] = useState(null);
  const [aufnahmenFiltered, aufnahmenFilteredSet] = useState(null);
  const [confirmModalData, confirmModalDataSet] = useState(null);
  const [error, errorSet] = useState(null);
  const [gigError, gigErrorSet] = useState(null);
  const [gigs, gigsSet] = useState(null);
  const [gigData, gigDataSet] = useState(null);
  const [news, newsSet] = useState(null);
  const [modifyMode, modifyModeSet] = useState(null);
  const [newsEntryDataOriginal, newsEntryDataOriginalSet] = useState(
    emptyEntities.newsEntry
  );
  const [newsEntryData, newsEntryDataSet] = useState(emptyEntities.newsEntry);
  const [settings, settingsSet] = useState(null);
  const [showPastGigs, showPastGigsSet] = useState(false);

  const authRequest = async (_type, _route, _params = {}) => {
    const token = localStorage.getItem("hstd_op");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let request = null;
    if (_type === "post") {
      request = axios[_type](`${props.server.url}/${_route}`, _params, {
        headers,
      });
    } else if (_type === "get") {
      request = axios[_type](`${props.server.url}/${_route}`, {
        headers,
        params: _params,
      });
    } else if (_type === "fileUpload") {
      request = axios.post(`${props.server.url}/${_route}`, _params, {
        headers,
      });
    }

    if (!request) {
      alert("Error: Request not defined");
    }

    return request
      .then((_response) => {
        return _response;
      })
      .catch((_error) => {
        if (
          _error.response &&
          _error.response.status &&
          _error.response.status === 401
        ) {
          return "unauthorized";
        }
        return "error";
      });
  };

  const BASE = {
    authRequest,
    emptyEntities,
    error,
    errorCreate: (_message) => {
      errorSet(_message);
      LOG.log(_message);
    },
    errorSet,
    modifyMode,
    modifyModeSet,
    onClickClaim: () => {
      const callback = () => {
        NEWS.newsEntryDataSet(BASE.emptyEntities.newsEntry);
        NEWS.newsEntryDataOriginalSet(BASE.emptyEntities.newsEntry);
        BASE.modifyModeSet(null);
        CONFIRMMODAL.dataSet(null);
        logout();
      };

      if (!NEWS.isChanged()) {
        callback();
        return;
      }

      CONFIRMMODAL.dataSet(CONFIRMMODAL.newsEntryData(callback));
    },
    settings: settings,
    settingsSet: settingsSet,
    settingUpdate: async (_newSetting) => {
      authRequest("post", "settingsUpdate", _newSetting)
        .then((_response) => {
          if (typeof _response.data !== "object") {
            BASE.errorCreate("Fehler H10007 beim Speichern der Einstellungen");
            return;
          }

          LOG.log("Einstellungen gespeichert", { settings: _response.data });
          settingsSet(_response.data);
        })
        .catch((_error) => {
          BASE.errorCreate(
            `Fehler H10008 beim Speichern der Einstellungen: ${_error}`
          );
        });
    },
    usernameGet: async () => {
      return authRequest("get", "userFindByEmail")
        .then((_response) => {
          return _response.data.name;
        })
        .catch((_error) => {
          BASE.errorCreate(`Fehler H10019 beim Laden des Benutzers: ${_error}`);
          return null;
        });
    },
  };

  const AUFNAHMEN = {
    audioIsLoadingName,
    audioIsLoadingNameSet,
    audioSources,
    audioSourcesSet,
    aufnahmen,
    aufnahmenSet,
    aufnahmenFilter,
    aufnahmenFilterSet,
    aufnahmenFiltered,
    aufnahmenFilteredSet,
    aufnahmenSelectedFilter,
    aufnahmenSelectedFilterSet,
    handleAufnahmenFilterChange: (_event) => {
      AUFNAHMEN.aufnahmenSelectedFilterSet(_event.target.value);
    },
    onClickDownload: async (_fileName) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = AUFNAHMEN.audioSources[_fileName];
      downloadLink.setAttribute("download", _fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode.removeChild(downloadLink);
    },
    onClickLoad: async (_file) => {
      AUFNAHMEN.audioIsLoadingNameSet(_file.name);

      await BASE.authRequest("get", "audioGet", { fileName: _file.name })
        .then((_response) => {
          audioSourcesSet((_prev) => {
            return {
              ..._prev,
              [_file.name]: "data:audio/mp3;base64," + _response.data,
            };
          });

          LOG.log("Aufnahme geladen", { filename: _file.name });
        })
        .catch((_error) => {
          BASE.errorCreate(`Fehler H10024 beim Laden der Aufnahme: ${_error}`);
        });

      AUFNAHMEN.audioIsLoadingNameSet(null);
    },
    onAudioPlay: (_event) => {
      const audioPlayers = document.querySelectorAll("audio");
      audioPlayers.forEach((_player) => {
        if (_player !== _event.target) {
          _player.pause();
        }
      });
    },
  };

  const CONFIRMMODAL = {
    data: confirmModalData,
    dataSet: confirmModalDataSet,
    newsEntryData: (_confirmCallback = () => {}) => {
      return {
        entity: null,
        onDeleteCancel: () => {
          CONFIRMMODAL.dataSet(null);
        },
        onDeleteConfirm: _confirmCallback,
        text: "Dieser News-Eintrag wurde verändert. Sollen diese Änderungen verworfen werden?",
        type: "menu",
      };
    },
  };

  const GIGS = {
    gigError,
    gigErrorSet,
    gigModeGet: (_gig) =>
      _gig.id === 0 && BASE.modifyMode === "create"
        ? "create"
        : GIGS.gigData !== null &&
          _gig.id === GIGS.gigData.id &&
          BASE.modifyMode === "edit"
        ? "edit"
        : "view",
    gigs,
    gigsSet,
    gigData,
    gigDataSet,
    inputs: [
      {
        className: "date",
        type: "date",
        name: "date",
        displayName: "Datum",
        mandatory: true,
      },
      {
        className: "time",
        type: "time",
        name: "startTime",
        displayName: "Start-Zeit",
        mandatory: true,
      },
      {
        className: "time",
        type: "time",
        name: "endTime",
        displayName: "End-Zeit",
        mandatory: true,
      },
      {
        className: "title",
        type: "text",
        name: "title",
        displayName: "Titel",
        mandatory: true,
      },
      {
        className: "link",
        type: "text",
        name: "anfahrt",
        displayName: "Anfahrts-Link (optional)",
        mandatory: false,
      },
      {
        className: "link",
        type: "text",
        name: "info",
        displayName: "Info-Link (optional)",
        mandatory: false,
      },
    ],
    handleGigDataChange: (_key, _value) => {
      gigDataSet({ ...gigData, [_key]: _value });
    },
    handleModalCancel: () => {
      confirmModalDataSet(null);
    },
    onClickCancel: async () => {
      confirmModalDataSet(null);
    },
    onClickConfirm: async (_gig) => {
      authRequest("post", "gigDelete", { gig: _gig })
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10025 beim Löschen des Gigs.");
            return;
          }

          LOG.log("Gig gelöscht", { gig: _gig });
          gigsSet(_response.data);
          confirmModalDataSet(null);
        })
        .catch((_error) => {
          BASE.errorCreate(`Fehler H10026 beim Löschen des Gigs: ${_error}`);
        });
    },
    onClickCreate: async () => {
      BASE.modifyModeSet("create");
      GIGS.gigDataSet(BASE.emptyEntities.gig);
    },
    onClickDiscard: async () => {
      modifyModeSet(null);
      gigDataSet(null);
      gigErrorSet(null);
    },
    onClickDelete: async (_gig) => {
      CONFIRMMODAL.dataSet({
        entity: _gig,
        onDeleteCancel: GIGS.onClickCancel,
        onDeleteConfirm: (_gig) => {
          GIGS.onClickConfirm(_gig);
        },
        text: `Soll der Auftritt '${_gig.title}' wirklich gelöscht werden?`,
        type: "gig",
      });
    },
    onClickEdit: async (_gig) => {
      gigDataSet(_gig);
      modifyModeSet("edit");
    },
    onClickSave: async () => {
      if (
        gigData.date === "" ||
        gigData.startTime === "" ||
        gigData.endTime === "" ||
        gigData.title === ""
      ) {
        gigErrorSet("Bitte fülle alle markierten Felder aus.");
        return;
      }

      gigErrorSet(null);

      authRequest("post", "gigAdd", GIGS.gigData)
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10009 beim Speichern des Gigs.");
            return;
          }

          LOG.log("Gig gespeichert", { gig: GIGS.gigData });
          modifyModeSet(null);
          GIGS.gigsSet(_response.data);
          GIGS.gigDataSet(null);
        })
        .catch((_error) => {
          BASE.errorCreate(`Fehler H10010 beim Speichern des Gigs: ${_error}`);
        });
    },
    onClickUpdate: async () => {
      authRequest("post", "gigUpdate", GIGS.gigData)
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10011 beim Aktualisieren des Gigs.");
            return;
          }

          LOG.log("Gig aktualisiert", { gig: GIGS.gigData });
          modifyModeSet(null);
          GIGS.gigsSet(_response.data);
          GIGS.gigDataSet(null);
        })
        .catch((_error) => {
          BASE.errorCreate(
            `Fehler H10012 beim Aktualisieren des Gigs: ${_error}`
          );
        });
    },
    onInput: (_event) => {
      _event.target.value = _event.target.value.replace(
        /[^-0-9a-zA-ZÄÖÜäöüß()/&%?=!.,;:_+ ]/g,
        ""
      );
    },
    showPastGigs,
    showPastGigsSet,
    startUp: () => {
      authRequest("get", "gigsGet")
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10013 beim Laden der Gigs.");
            return;
          }

          gigsSet(
            _response.data.sort((_a, _b) => _b.date.localeCompare(_a.date))
          );
        })
        .catch((_error) => {
          BASE.errorCreate(`Fehler H10014 beim Laden der Gigs: ${_error}`);
        });
    },
    types: ["Öffentlich", "Privat"],
  };

  const LOG = {
    log: async (_message, _params = {}) => {
      const username = await BASE.usernameGet();

      if (!username) return;

      return axios
        .post(`${props.server.url}/log`, {
          username,
          message: _message,
          params: _params,
        })
        .catch(() => {});
    },
  };

  const MENU = {
    activeMenuItem,
    activeMenuItemSet,
    onLogout: () => {
      const callback = () => {
        NEWS.newsEntryDataSet(BASE.emptyEntities.newsEntry);
        NEWS.newsEntryDataOriginalSet(BASE.emptyEntities.newsEntry);
        BASE.modifyModeSet(null);
        logout();
        CONFIRMMODAL.dataSet(null);
      };

      if (!NEWS.isChanged()) {
        callback();
        return;
      }

      CONFIRMMODAL.dataSet(CONFIRMMODAL.newsEntryData(callback));
    },
    onMenuItemClick: (_code) => {
      const callback = () => {
        NEWS.newsEntryDataSet(BASE.emptyEntities.newsEntry);
        NEWS.newsEntryDataOriginalSet(BASE.emptyEntities.newsEntry);
        BASE.modifyModeSet(null);
        MENU.activeMenuItemSet(_code);
        CONFIRMMODAL.dataSet(null);
      };

      if (!NEWS.isChanged()) {
        callback();
        return;
      }

      CONFIRMMODAL.dataSet(CONFIRMMODAL.newsEntryData(callback));
    },
  };

  const NEWS = {
    isChanged: () => {
      return (
        JSON.stringify(newsEntryData) !== JSON.stringify(newsEntryDataOriginal)
      );
    },
    handleDataChange: (_key, _value) => {
      newsEntryDataSet({ ...newsEntryData, [_key]: _value });
    },
    news,
    newsEntryData,
    newsEntryDataSet,
    newsEntryDataOriginal,
    newsEntryDataOriginalSet,
    modifyMode,
    modifyModeSet,
    onClickDelete: async (_newsEntry) => {
      CONFIRMMODAL.dataSet({
        entity: _newsEntry,
        onDeleteCancel: NEWS.onClickDeleteCancel,
        onDeleteConfirm: (_newsEntry) => {
          NEWS.onClickDeleteConfirm(_newsEntry);
        },
        text: `Soll der News-Eintrag '${_newsEntry.title}' wirklich gelöscht werden?`,
        type: "news",
      });
    },
    onClickDeleteCancel: async () => {
      CONFIRMMODAL.dataSet(null);
    },
    onClickDeleteConfirm: async (_newsEntry) => {
      await authRequest("post", "newsDeleteEntry", { id: _newsEntry.id })
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10015 beim Löschen des News-Eintrags.");
            return;
          }

          LOG.log("News-Eintrag gelöscht", { newsEntry: _newsEntry });
          newsSet(_response.data);
        })
        .catch((_error) => {
          BASE.errorCreate(
            `Fehler H10016 beim Löschen des News-Eintrags: ${_error}`
          );
        });

      CONFIRMMODAL.dataSet(null);
    },
    onClickDiscard: async () => {
      const callback = () => {
        NEWS.newsEntryDataSet(BASE.emptyEntities.newsEntry);
        NEWS.newsEntryDataOriginalSet(BASE.emptyEntities.newsEntry);
        BASE.modifyModeSet(null);
        CONFIRMMODAL.dataSet(null);
      };

      if (!NEWS.isChanged()) {
        callback();
        return;
      }

      CONFIRMMODAL.dataSet(CONFIRMMODAL.newsEntryData(callback));
    },
    onClickEdit: async (_id) => {
      authRequest("get", "newsGetEntry", { id: _id })
        .then((_response) => {
          if (typeof _response.data !== "object") {
            BASE.errorCreate("Fehler H10017 beim Laden des News-Eintrags.");
            return;
          }

          newsEntryDataSet(_response.data);
          newsEntryDataOriginalSet(_response.data);
          modifyModeSet("edit");
        })
        .catch((_error) => {
          BASE.errorCreate(
            `Fehler H10018 beim Laden des News-Eintrags: ${_error}`
          );
        });
    },
    onClickSave: async () => {
      const username = await BASE.usernameGet();

      const tmpNewsEntryData = {
        ...newsEntryData,
        autor: username,
      };

      const route =
        modifyMode === "create"
          ? "newsSaveCreatedEntry"
          : "newsSaveEditedEntry";

      authRequest("post", route, tmpNewsEntryData)
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10020 beim Speichern des News-Eintrags.");
            return;
          }

          LOG.log("News-Eintrag gespeichert", {
            newsEntry: tmpNewsEntryData,
            modifyMode,
          });
          newsSet(_response.data);
          modifyModeSet(null);
          newsEntryDataSet(BASE.emptyEntities.newsEntry);
          newsEntryDataOriginalSet(BASE.emptyEntities.newsEntry);
        })
        .catch((_error) => {
          BASE.errorCreate(
            `Fehler H10021 beim Speichern des News-Eintrags: ${_error}`
          );
        });
    },
    onClickCreate: () => {
      BASE.modifyModeSet("create");
    },
    onClickVisibility: async (_id, _newStatus) => {
      authRequest("post", "newsToggleVisibility", {
        id: _id,
        newStatus: _newStatus,
      })
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10022 beim Ändern der Sichtbarkeit.");
            return;
          }

          LOG.log("Sichtbarkeit geändert", { id: _id, newStatus: _newStatus });
          newsSet(_response.data);
        })
        .catch((_error) => {
          BASE.errorCreate(
            `Fehler H10023 beim Ändern der Sichtbarkeit: ${_error}`
          );
        });
    },
    newsSet,
    newsFetch: async () => {},
    startUp: async () => {
      axios
        .get(`${props.server.url}/newsGet`)
        .then((_response) => {
          if (!Array.isArray(_response.data)) {
            BASE.errorCreate("Fehler H10001 beim Laden der News.");
            return;
          }

          newsSet(_response.data);
        })
        .catch((_error) => {
          BASE.errorCreate(`Fehler H10002 beim Laden der News: ${_error}`);
        });
    },
  };

  const startUp = async () => {
    authRequest("get", "audiosGet")
      .then((_response) => {
        if (!Array.isArray(_response.data)) {
          BASE.errorCreate("Fehler H10003 beim Laden der Aufnahmen.");
          return;
        }

        const filterTitles = _response.data.map((_audioFiles) =>
          _audioFiles.name.split(" - ")[1].replace(".mp3", "")
        );

        filterTitles.push("Alle Titel");
        filterTitles.sort((_a, _b) => _a.localeCompare(_b));

        // unique filter values
        const tmpAufnahmenFilterSet = new Set(filterTitles);

        aufnahmenFilterSet([...tmpAufnahmenFilterSet]);
        aufnahmenSet(_response.data);
      })
      .catch((_error) => {
        BASE.errorCreate(`Fehler H10004 beim Laden der Aufnahmen: ${_error}`);
      });

    axios.get(`${props.server.url}/settingsGet`).then((_response) => {
      if (!typeof _response.data === "object") {
        BASE.errorCreate("Fehler H10005 beim Laden der Einstellungen.");
        return;
      }

      settingsSet(_response.data);
    });

    LOG.log("Admin-Panel gestartet");
  };

  useEffect(() => {
    startUp();
  }, []);

  useEffect(() => {
    checkAuth();
  }, [activeMenuItem]);

  useEffect(() => {
    if (!aufnahmen || !aufnahmenFilter) return;

    aufnahmenSelectedFilterSet("Alle Titel");
  }, [aufnahmen, aufnahmenFilter]);

  useEffect(() => {
    if (!aufnahmen || !aufnahmenFilter) return;

    aufnahmenFilteredSet(
      aufnahmenSelectedFilter === "Alle Titel"
        ? aufnahmen
        : aufnahmen.filter((_audio) =>
            _audio.name.includes(aufnahmenSelectedFilter)
          )
    );
  }, [aufnahmenSelectedFilter]);

  useEffect(() => {
    window.onbeforeunload = (_event) => {
      if (!NEWS.isChanged()) return;

      const event = _event || window.event;

      event.preventDefault();
    };
  }, [newsEntryData, newsEntryDataOriginal]);

  return (
    <AdminContext.Provider
      value={{
        AUFNAHMEN,
        BASE,
        CONFIRMMODAL,
        GIGS,
        LOG,
        MENU,
        NEWS,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
