//& react & mui imports =======================================================================
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin.js";

//& component =================================================================================
const AufnahmenFilterSelect = () => {
  const { AUFNAHMEN } = useAdmin();

  //& rendering ===============================================================================
  return (
    <div className="aufnahme-filterSelect">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Filter auswählen</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={AUFNAHMEN.aufnahmenSelectedFilter}
          label="Filter auswählen"
          onChange={AUFNAHMEN.handleAufnahmenFilterChange}
        >
          {AUFNAHMEN.aufnahmenFilter.map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AufnahmenFilterSelect;
