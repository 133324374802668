import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./router/route";
import AuthProvider from "./contexts/AuthProvider";
import { DeviceDisplayProvider } from "./contexts/DeviceDisplayProvider";
import { AdminProvider } from "./contexts/AdminProvider";
import { FrontendProvider } from "./contexts/FrontendProvider";
import HSOP from "./components/hsop/HSOP";
import Login from "./components/hsop/Login";
import FrontEnd from "./FrontEnd";
import "./styles/main.css";
import "./styles/mobile.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <DeviceDisplayProvider>
          <Routes>
            <Route
              path="/"
              element={
                <FrontendProvider>
                  <FrontEnd />
                </FrontendProvider>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="/hsop"
                element={
                  <AdminProvider>
                    <HSOP />
                  </AdminProvider>
                }
              />
            </Route>
          </Routes>
        </DeviceDisplayProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
