//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const DiskographieMobile = () => {
  const { BASE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="bubble">
      <div className="discography-cd">
        <div className="gapMedium alignCenter">
          <img
            className="discography-cdImage"
            src={RESOURCES.DISCOGRAPHY[0].image}
            alt={RESOURCES.DISCOGRAPHY[0].alt}
            onClick={() => {
              BASE.onImageClick(RESOURCES.DISCOGRAPHY[0].image);
            }}
          />
          <div className="flexColumn gapSmall">
            <h3>
              {RESOURCES.DISCOGRAPHY[0].year}:<br />
              <br />
              {RESOURCES.DISCOGRAPHY[0].name}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: RESOURCES.TEXTS.cd4DescriptionTextHTML,
              }}
            />
          </div>
        </div>

        <div key={`cd_x_divider`} className="divider" />
      </div>

      {RESOURCES.DISCOGRAPHY.slice(1).map((_cd, _index) => (
        <div key={_index} className="discography-cd">
          <div className="flexRow gapMedium alignCenter">
            <img
              className="discography-cdImage"
              src={_cd.image}
              alt={_cd.alt}
              onClick={() => {
                BASE.onImageClick(_cd.image);
              }}
            />
            <div className="flexColumn gapSmall">
              <h3>
                {_cd.year}:<br />
                <br />
                {_cd.name}
              </h3>
            </div>
          </div>

          {/* divide when necessary */}
          {_index !== RESOURCES.DISCOGRAPHY.length - 2 && (
            <div key={`cd_${_index}_divider`} className="divider" />
          )}
        </div>
      ))}
    </div>
  );
};

export default DiskographieMobile;
