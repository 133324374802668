//& react & mui imports =======================================================================
import React from "react";
import TextField from "@mui/material/TextField";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin";

//& component =================================================================================
const GigInput = ({
  className,
  name,
  type,
  gig,
  gigMode,
  disabled,
  displayName,
}) => {
  const { GIGS } = useAdmin();

  //& rendering ===============================================================================
  return (
    <div>
      <TextField
        InputLabelProps={{ shrink: true }}
        className={className}
        name={name}
        size="small"
        label={displayName}
        type={type}
        value={gigMode === "view" ? gig[name] : GIGS.gigData[name]}
        disabled={disabled}
        onInput={GIGS.onInput}
        onChange={(_event) => {
          GIGS.handleGigDataChange(name, _event.target.value);
        }}
      />
    </div>
  );
};

export default GigInput;
