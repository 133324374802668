//& react & mui imports =======================================================================
import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";
import useDeviceDisplay from "../../hooks/useDeviceDisplay.js";

//& component =================================================================================
const ImageModal = () => {
  const { BASE } = useFrontend();
  const { displayMode } = useDeviceDisplay;

  //& rendering ===============================================================================
  return (
    <Modal
      open={BASE.modalData && BASE.modalData.type === "text"}
      onClose={() => {
        BASE.modalDataSet(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: displayMode === "normal" ? "90vw" : "70vw",
          bgcolor: "background.paper",
          border: "2px solid lightgray",
          borderRadius: "0.5rem",
          boxShadow: 24,
          p: 4,
        }}
        className="textModal-box"
      >
        <div
          dangerouslySetInnerHTML={{ __html: BASE.modalData.data }}
          className="textModal-text"
        ></div>
      </Box>
    </Modal>
  );
};

export default ImageModal;
