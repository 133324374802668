//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "./hooks/useFrontend.js";

//& component imports =========================================================================
import HeaderMobile from "./components/_mobile/HeaderMobile";
import MenuMobile from "./components/_mobile/Menu/MenuMobile.jsx";
import ContentMobile from "./components/_mobile/ContentMobile.jsx";
import ImageModal from "./components/Modals/ImageModal.jsx";
import TextModal from "./components/Modals/TextModal.jsx";

//& style imports =============================================================================
import "./styles/misc.css";
import "./styles/frontend.css";

//& component =================================================================================
function FrontEndMobile() {
  const { BASE, MOBILE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <>
      {MOBILE.isMobileMenuOpen && <MenuMobile />}
      {BASE.modalData && BASE.modalData.type === "image" && <ImageModal />}
      {BASE.modalData && BASE.modalData.type === "text" && <TextModal />}
      <div className="mobile-frontend">
        <HeaderMobile />
        <ContentMobile />
      </div>
    </>
  );
}

export default FrontEndMobile;
