//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import BandMember from "./BandMember.jsx";

//& component =================================================================================
const Band = () => {
  const { RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn alignCenter">
      <div className="flexRow gapSmall justifyCenter wrap bandmembers">
        {RESOURCES.CREWMEMBERS.map((_member) => (
          <BandMember key={_member.name} member={_member} />
        ))}
      </div>
    </div>
  );
};

export default Band;
