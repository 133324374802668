//& react & mui imports =======================================================================
import React from "react"

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import BaseIconButton from "../BaseControls/BaseIconButton.jsx";

//& component =================================================================================
const GalerieMobile = () => {
  const { BASE, RESOURCES} = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapSmall">

        <div className="flexColumn gapSmall alignCenter">

{RESOURCES.IMAGES.galleryImages.map((_image, _index) => {
        return (<div key={_index} className="mobile-polaroid" polaroid-caption={`© ${_image.copyright}`}>
        <img
          src={_image.imageSmall}
          width="250"
          alt="Hardt Stompers Galerie-Bild"
          onClick={() => {
            BASE.modalDataSet({
              type: "image",
              data: _image.imageOriginal,
            });
          }}
        />
      </div>)
})}


        </div>




        <div className="bubble">
          <div className="flexRow gapSmall alignCenter"               onClick={() => {
                BASE.modalDataSet({
                  type: "image",
                  data: RESOURCES.IMAGES.Presse_Gaeubote,
                });
              }}>
            <BaseIconButton
              iconType="openIn"

            />
            Bericht aus dem Gäuboten zum Auftritt in Gärtringen
          </div>
        </div>

        <div className="bubble">
          <div className="flexRow gapSmall alignCenter"               onClick={() => {
                BASE.modalDataSet({
                  type: "text",
                  data: RESOURCES.TEXTS.schoeneckerTextHTML,
                });
              }}>
            <BaseIconButton
              iconType="openIn"
            />
            Bericht zur aktuellen CD "When My Dreamboat Comes Home" -<br />
            von Dr. Helmut Schönecker
          </div>
        </div>
    </div>
  )
};

export default GalerieMobile;
