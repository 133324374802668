//& react & mui imports =======================================================================
import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin.js";
import useDeviceDisplay from "../../../hooks/useDeviceDisplay.js";

//& component imports =========================================================================
import NewsEditor from "./Editor/NewsEditor.jsx";
import BaseIconButton from "../../BaseControls/BaseIconButton.jsx";
import BaseButton from "../../BaseControls/BaseButton.jsx";
import ErrorAlert from "../ErrorAlert.jsx";

//& logic imports =============================================================================
import { dateISOtoLocaleString } from "../../../logic/jsHelper.js";

//& component =================================================================================
const News = () => {
  const { BASE, NEWS } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  useEffect(() => {
    NEWS.startUp();
  }, []);

  const classNames = {
    wrapper: displayMode === "mobile" ? "mobile-bubble" : "bubble contentWidth",
    newsEntryWrapper:
      displayMode === "mobile"
        ? "flexRow alignCenter gapSmall wrap"
        : "flexRow alignCenter gapSmall",
  };

  //& rendering ===============================================================================
  if (!NEWS.news) {
    return <>Einträge werden geladen...</>;
  }

  return (
    <div className={classNames.wrapper}>
      {BASE.error && <ErrorAlert />}
      <div className="flexRow gapLarge alignCenter">
        {/* "Neuer Eintrag"-Button */}
        {NEWS.news && !BASE.modifyMode && BASE.modifyMode === null && (
          <BaseButton
            onClick={NEWS.onClickCreate}
            caption="Neuer Eintrag"
            width="8rem"
          />
        )}

        {/* "Anzahl Beiträge auf Hauptseite"-Select */}
        {BASE.modifyMode === null && (
          <div className="news-newsEntryCount">
            {BASE.settings && (
              <FormControl className="news-newsEntryCount-select" fullWidth>
                <InputLabel id="news-entryCount-label">
                  Anzahl Beiträge auf Hauptseite
                </InputLabel>
                <Select
                  labelId="news-entryCount-label"
                  value={BASE.settings.newsDisplayCount}
                  label="Anzahl Beiträge auf Hauptseite"
                  onChange={(_event) => {
                    BASE.settingUpdate({
                      newsDisplayCount: parseInt(_event.target.value),
                    });
                  }}
                >
                  {Array.from(Array(10).keys()).map((_x) => (
                    <MenuItem key={_x + 1} value={_x + 1}>
                      {_x + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
      </div>

      {/* Liste der News-Einträge */}
      {BASE.modifyMode === null &&
        NEWS.news.map((_entry, _index) => {
          return (
            <div key={_entry.id} className="newsEntries">
              {_index !== 0 && <div className="news-divider" />}
              <div className={classNames.newsEntryWrapper}>
                <div className="newsTable-title">{_entry.title}</div>
                <div className="newsTable-date">
                  {dateISOtoLocaleString(_entry.date)}
                </div>
                <div className="newsTable-author">{_entry.autor}</div>
                <div className="flexRow alignCenter gapSmall">
                  <BaseIconButton
                    iconType={_entry.visible ? "visible" : "invisible"}
                    onClick={() => {
                      NEWS.onClickVisibility(_entry.id, !_entry.visible);
                    }}
                    tooltip={
                      _entry.visible ? "unsichtbar machen" : "sichtbar machen"
                    }
                    disabled={BASE.modifyMode !== null}
                  />
                  <BaseIconButton
                    iconType="edit"
                    onClick={() => {
                      NEWS.onClickEdit(_entry.id);
                    }}
                    tooltip="Bearbeiten"
                    disabled={BASE.modifyMode !== null}
                  />
                  <BaseIconButton
                    iconType="bin"
                    onClick={() => {
                      NEWS.onClickDelete(_entry);
                    }}
                    tooltip="Löschen"
                    disabled={BASE.modifyMode !== null}
                  />
                </div>
              </div>
            </div>
          );
        })}

      {/* Editor */}
      {BASE.modifyMode && (
        <div className="flexColumn gapMedium">
          <div>
            <NewsEditor />
          </div>
          <div className="flexRow alignCenter gapMedium">
            <BaseButton onClick={NEWS.onClickDiscard} caption="Verwerfen" />
            <BaseButton onClick={NEWS.onClickSave} caption="Speichern" />
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
