//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useDeviceDisplay from "./hooks/useDeviceDisplay";

//& component imports =========================================================================
import Header from "./components/Header";
import Menu from "./components/Menu/Menu";
import Content from "./components/Content";
import Footer from "./components/Footer";
import FrontEndMobile from "./FrontEndMobile";

//& style imports =============================================================================
import "./styles/misc.css";
import "./styles/frontend.css";

//& component =================================================================================
function FrontEnd() {
  const { displayMode } = useDeviceDisplay();

  //& rendering ===============================================================================
  if (displayMode === "mobile") {
    return <FrontEndMobile />;
  }

  return (
    <div>
      <Header />
      <Menu />
      <Content />
      <Footer />
    </div>
  );
}

export default FrontEnd;
