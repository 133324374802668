//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const MenuItem = ({ code, label }) => {
  const { MENU } = useFrontend();

  const className = `menuitem menuitem-${
    MENU.activeMenuItem === code ? "active" : "inactive"
  }`;

  const style = {
    width: `${label.length * 10}px`,
  };

  //& rendering ===============================================================================
  return (
    <div
      className={className}
      onClick={() => MENU.onMenuItemClick(code)}
      style={style}
    >
      {label}
    </div>
  );
};

export default MenuItem;
