//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import MenuItemMobile from "./MenuItemMobile.jsx";

//& component =================================================================================
const MenuMobile = () => {
  //& rendering ===============================================================================
  return (
    <div className="mobile-menu">
      <MenuItemMobile code="start" label="Start" />
      <MenuItemMobile code="band" label="Die Band" />
      <MenuItemMobile code="galerie" label="Galerie" />
      <MenuItemMobile code="diskographie" label="Diskographie" />
      <MenuItemMobile code="veranstalter" label="Veranstalter" />
      <MenuItemMobile code="kontakt" label="Kontakt" />
      <MenuItemMobile code="impressum" label="Impressum" />
      <MenuItemMobile
        code="datenschutzerklaerung"
        label="Datenschutzerklärung"
      />
      <MenuItemMobile code="facebook" label="Wir auf Facebook" />
      <MenuItemMobile code="youtube" label="Wir auf Youtube" />
    </div>
  );
};

export default MenuMobile;
