//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Lock from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";
import MailLockIcon from "@mui/icons-material/MailLock";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

//& component =================================================================================
const BaseIconButton = ({
  color = "primary",
  disabled = false,
  disableRipple = true,
  label = null,
  iconType,
  onClick = () => {},
  size = "medium",
  tooltip = "",
}) => {
  const iconButton = (
    <IconButton
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      onClick={onClick}
      size={size}
    >
      {iconType === "openIn" && <OpenInNewIcon />}
      {iconType === "add" && <AddCircleOutlineIcon />}
      {iconType === "bin" && <DeleteIcon />}
      {iconType === "edit" && <EditIcon />}
      {iconType === "edit2" && <EditNoteIcon />}
      {iconType === "locked" && <Lock />}
      {iconType === "unlocked" && <LockOpen />}
      {iconType === "mailRead" && <MarkEmailReadIcon />}
      {iconType === "save" && <SaveAsIcon />}
      {iconType === "cancel" && <ClearIcon />}
      {iconType === "mailLocked" && <MailLockIcon />}
      {iconType === "download" && <FileDownloadIcon />}
      {iconType === "fullscreen" && <FullscreenIcon />}
      {iconType === "keyboard" && <KeyboardIcon />}
      {iconType === "like" && <ThumbUpIcon />}
      {iconType === "refresh" && <RefreshIcon />}
      {iconType === "unlike" && <ThumbUpAltOutlinedIcon />}
      {iconType === "ticked" && <TaskAltIcon />}
      {iconType === "unticked" && <HighlightOffIcon />}
      {iconType === "visible" && <VisibilityIcon />}
      {iconType === "invisible" && <VisibilityOffIcon />}
      {label && label}
    </IconButton>
  );

  //& rendering -------------------------------------------------------------------------------
  return tooltip ? (
    <Tooltip title={disabled ? null : tooltip}>
      <span>{iconButton}</span>
    </Tooltip>
  ) : (
    iconButton
  );
};

export default BaseIconButton;
