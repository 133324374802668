//& react & mui imports =======================================================================
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import IconButton from "@mui/material/IconButton";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Tooltip from "@mui/material/Tooltip";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin";
import useDeviceDisplay from "../../../hooks/useDeviceDisplay";

//& component imports =========================================================================
import GigTypeSelect from "./GigTypeSelect";

//& component imports =========================================================================
import GigInput from "./GigInput";

//& component =================================================================================
const Gig = ({ gig }) => {
  const { BASE, GIGS } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  const classNames = {
    gigTypeSelect:
      displayMode === "normal"
        ? ""
        : "gigArea-gig-input gigArea-gig-input-type",
  };

  const mode = GIGS.gigModeGet(gig);

  const isFieldsDisabled = mode === "view";

  const buttonEdit = {
    callback: () => {
      GIGS.onClickEdit(gig);
    },
    disabled: BASE.modifyMode !== null,
    icon: <EditIcon />,
    tooltipTitle: "Auftritt bearbeiten",
  };

  const buttonSave = {
    callback: () => {
      GIGS.onClickSave();
    },
    disabled: false,
    icon: <SaveAsIcon />,
    tooltipTitle: "Auftritt speichern",
  };

  const buttonUpdate = {
    callback: () => {
      GIGS.onClickUpdate();
    },
    disabled: false,
    icon: <SaveAsIcon />,
    tooltipTitle: "Änderungen speichern",
  };

  const buttonDelete = {
    callback: () => {
      GIGS.onClickDelete(gig);
    },
    disabled: BASE.modifyMode !== null,
    icon: <HighlightOffOutlinedIcon />,
    tooltipTitle: "Auftritt löschen",
  };

  const buttonDiscard = {
    callback: () => {
      GIGS.onClickDiscard();
    },
    disabled: false,
    icon: <CloseIcon />,
    tooltipTitle: "Änderungen verwerfen",
  };

  const buttons =
    mode === "view"
      ? [buttonEdit, buttonDelete]
      : mode === "edit"
      ? [buttonUpdate, buttonDiscard]
      : [buttonSave, buttonDiscard];

  //& rendering ===================================================================================
  if (displayMode === "mobile") {
    return (
      <div className="flexColumn gapSmall">
        <div className="flexRow gapSmall">
          <GigTypeSelect
            className={classNames.gigTypeSelect}
            disabled={isFieldsDisabled}
            gig={gig}
            mode={mode}
          />

          <GigInput
            className={`gigArea-gig-input gigArea-gig-input-${GIGS.inputs[0].className}`}
            name={GIGS.inputs[0].name}
            type={GIGS.inputs[0].type}
            gig={gig}
            gigMode={mode}
            disabled={isFieldsDisabled}
            displayName={GIGS.inputs[0].displayName}
          />
        </div>
        <div className="flexRow gapSmall">
          {GIGS.inputs.slice(1, 3).map((_input, _index) => (
            <GigInput
              key={_index}
              className={`gigArea-gig-input gigArea-gig-input-${_input.className}`}
              name={_input.name}
              type={_input.type}
              gig={gig}
              gigMode={mode}
              disabled={isFieldsDisabled}
              displayName={_input.displayName}
            />
          ))}
        </div>

        <GigInput
          className={`gigArea-gig-input gigArea-gig-input-${GIGS.inputs[3].className}`}
          name={GIGS.inputs[3].name}
          type={GIGS.inputs[3].type}
          gig={gig}
          gigMode={mode}
          disabled={isFieldsDisabled}
          displayName={GIGS.inputs[3].displayName}
        />

        <div className="flexRow gapLarger">
          <div className="flexColumn gapSmall">
            {GIGS.inputs.slice(4).map((_input, _index) => (
              <GigInput
                key={_index}
                className={`gigArea-gig-input gigArea-gig-input-${_input.className}`}
                name={_input.name}
                type={_input.type}
                gig={gig}
                gigMode={mode}
                disabled={isFieldsDisabled}
                displayName={_input.displayName}
              />
            ))}
          </div>
          <div className="flexColumn gapSmall">
            {buttons.map((_button, _index) => (
              <Tooltip key={_index} title={_button.tooltipTitle}>
                <span>
                  <IconButton
                    disabled={_button.disabled}
                    onClick={_button.callback}
                    size="small"
                  >
                    {_button.icon}
                  </IconButton>
                </span>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flexColumn gapSmall gigArea-gig">
      {/* first row with date, times, type, buttons */}
      <div className="flexRow gapSmall">
        {GIGS.inputs.slice(0, 3).map((_input, _index) => (
          <GigInput
            key={_index}
            className={`gigArea-gig-input gigArea-gig-input-${_input.className}`}
            name={_input.name}
            type={_input.type}
            gig={gig}
            gigMode={mode}
            disabled={isFieldsDisabled}
            displayName={_input.displayName}
          />
        ))}

        <GigTypeSelect
          className={classNames.gigTypeSelect}
          disabled={isFieldsDisabled}
          gig={gig}
          mode={mode}
        />

        <div className="flexRow gapSmall justifyEnd gigArea-gig-buttons">
          {buttons.map((_button, _index) => (
            <Tooltip key={_index} title={_button.tooltipTitle}>
              <span>
                <IconButton
                  disabled={_button.disabled}
                  onClick={_button.callback}
                  size="small"
                >
                  {_button.icon}
                </IconButton>
              </span>
            </Tooltip>
          ))}
        </div>
      </div>

      {/* second row with title, links */}
      <div className="flexRow gapSmall">
        {GIGS.inputs.slice(3, 6).map((_input, _index) => (
          <GigInput
            key={_index}
            className={`gigArea-gig-input  gigArea-gig-input-${_input.className}`}
            name={_input.name}
            type={_input.type}
            gig={gig}
            gigMode={mode}
            disabled={isFieldsDisabled}
            displayName={_input.displayName}
          />
        ))}
      </div>
    </div>
  );
};

export default Gig;
