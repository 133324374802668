//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import TextField from "@mui/material/TextField";
import BaseButton from "../BaseControls/BaseButton.jsx";

//& component =================================================================================
const Kontakt = () => {
  const { BASE, KONTAKT, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapSmall alignCenter textCenter">
      <div className="bubble kontakt-text">
        <div
          dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.kontaktText1HTML }}
        />
        <p>
          <span
            className="kontakt-link"
            onClick={() => {
              BASE.activeMenuItemSet("veranstalter");
            }}
          >
            {RESOURCES.TEXTS.kontaktText2HTML}
          </span>
          {RESOURCES.TEXTS.kontaktText3HTML}
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.kontaktText4HTML }}
        />
      </div>

      {/* original, wenn nachricht geklärt ist */}
      {/* <div className="bubble flexRow gapLarge alignCenter kontakt-telefon">
        <div>
          <img
            className="kontakt-telefon-phoneIcon"
            src={RESOURCES.IMAGES.PhoneIcon}
            alt={RESOURCES.TEXTS.phone}
          />
        </div>

        {RESOURCES.CREWMEMBERS.filter((_member) => _member.contactUser).map(
          (_member, _index) => {
            return (
              <div key={_index} className="flexRow gapSmall alignCenter">
                <img
                  src={_member.imageFaceOnly}
                  alt={_member.name}
                  className="kontakt-telefon-image"
                />
                <div className="flexColumn">
                  <div className="kontakt-data">
                    {_member.name}
                    <br />
                    {_member.phone}
                  </div>
                </div>
              </div>
            );
          }
        )
      </div> */}

      {/* temporär */}
      <div className="bubble flexRow gapLarge alignCenter kontakt-nachricht">
        <div className="flexColumn gapSmall">
          <img
            className="kontakt-telefon-phoneIcon"
            src={RESOURCES.IMAGES.PhoneIcon}
            alt={RESOURCES.TEXTS.phone}
          />
          <img
            className="kontakt-telefon-phoneIcon"
            src={RESOURCES.IMAGES.MailIcon}
            alt={RESOURCES.TEXTS.mail}
          />
        </div>

        {RESOURCES.CREWMEMBERS.filter((_member) => _member.contactUser).map(
          (_member, _index) => {
            return (
              <div key={_index} className="flexRow gapSmall alignCenter">
                <img
                  src={_member.imageFaceOnly}
                  alt={_member.name}
                  className="kontakt-telefon-image"
                />
                <div className="flexColumn">
                  <div className="kontakt-data">
                    {_member.name}
                    <br />
                    {_member.phone}
                    <br />
                    <a href={`mailto:${_member.email}`}>E-Mail senden</a>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>

      {/* <div className="bubble flexRow gapSmall kontakt-nachricht">
        <div>
          <img
            className="kontakt-telefon-mailIcon"
            src={RESOURCES.IMAGES.MailIcon}
            alt={RESOURCES.TEXTS.message}
          />
        </div>

        <div className="flexColumn gapSmall alignEnd kontakt-nachricht-textfield-wrapper">
          <TextField
            className="kontakt-nachricht-textfield"
            id="kontakt"
            size="small"
            label={RESOURCES.TEXTS.messagePlaceholder}
            variant="outlined"
            multiline
            rows={12}
            value={KONTAKT.message}
            onChange={KONTAKT.handleMessageChange}
          />
          <BaseButton
            variant="contained"
            color="primary"
            caption={RESOURCES.TEXTS.send}
            onClick={KONTAKT.mailSend}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Kontakt;
