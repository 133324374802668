//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import BaseButton from "../BaseControls/BaseButton.jsx";

//& component =================================================================================
const VeranstalterDocument = ({
  doc,
  name,
  thumbnail,
  text,
  thumbnailWidth = 100,
  height = "18rem",
  width = null,
}) => {
  const { BASE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div
      className="flexColumn bubble justifySpaceBetween alignCenter"
      style={{ height, width }}
    >
      <div className="veranstalterdocument-title">
        <font size="4">
          <b>{name}</b>
        </font>
      </div>
      <img
        src={thumbnail}
        alt={name}
        width={thumbnailWidth}
        onClick={() => {
          BASE.onImageClick(thumbnail);
        }}
      />

      <div>
        <i>{text}</i>
      </div>
      <BaseButton
        variant="outlined"
        color="primary"
        caption="Download"
        onClick={() => {
          BASE.onDownloadClick(doc, name);
        }}
      />
    </div>
  );
};

export default VeranstalterDocument;
