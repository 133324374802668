//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import VeranstalterDocument from "./VeranstalterDocument.jsx";

//& component =================================================================================
const Veranstalter = () => {
  const { RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapMedium alignCenter">
      <div className="flexRow gapSmall">
        <VeranstalterDocument
          name={RESOURCES.VERANSTALTERDOCUMENTS[0].name}
          doc={RESOURCES.VERANSTALTERDOCUMENTS[0].doc}
          thumbnail={RESOURCES.VERANSTALTERDOCUMENTS[0].thumbnail}
          text={RESOURCES.VERANSTALTERDOCUMENTS[0].text}
          type={RESOURCES.VERANSTALTERDOCUMENTS[0].type}
          thumbnailWidth={300}
          height="28rem"
        />
        <VeranstalterDocument
          name={RESOURCES.VERANSTALTERDOCUMENTS[1].name}
          doc={RESOURCES.VERANSTALTERDOCUMENTS[1].doc}
          thumbnail={RESOURCES.VERANSTALTERDOCUMENTS[1].thumbnail}
          text={RESOURCES.VERANSTALTERDOCUMENTS[1].text}
          type={RESOURCES.VERANSTALTERDOCUMENTS[1].type}
          thumbnailWidth={336}
          height="28rem"
        />
      </div>
      <div className="flexRow gapSmall">
        {RESOURCES.VERANSTALTERDOCUMENTS.slice(2, 5).map((document, index) => {
          return (
            <VeranstalterDocument
              key={index}
              name={document.name}
              doc={document.doc}
              thumbnail={document.thumbnail}
              text={document.text}
              type={document.type}
              width="12rem"
            />
          );
        })}
      </div>
      <div className="flexRow gapSmall">
        {RESOURCES.VERANSTALTERDOCUMENTS.slice(5, 7).map((document, index) => {
          return (
            <VeranstalterDocument
              key={index}
              name={document.name}
              doc={document.doc}
              thumbnail={document.thumbnail}
              thumbnailWidth={75}
              text={document.text}
              type={document.type}
              height="15rem"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Veranstalter;
