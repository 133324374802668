//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import HelloTextMobile from "./Start/HelloTextMobile.jsx";
import NextGigsMobile from "./Start/NextGigsMobile.jsx";
import NewsEntriesMobile from "./Start/NewsEntriesMobile.jsx";

//& component =================================================================================
const StartMobile = () => {
  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapSmall">
      <HelloTextMobile />
      <NextGigsMobile />
      <NewsEntriesMobile />
    </div>
  );
};

export default StartMobile;
