//& react imports =============================================================================
import React, { createContext, useLayoutEffect, useState } from "react";

const DeviceDisplayContext = createContext({});

export const DeviceDisplayProvider = ({ children }) => {
  const [windowSize, windowSizeSet] = useState({ width: 0, height: 0 });
  const [displayMode, displayModeSet] = useState("normal");

  const updateDisplayMode = (_size) => {
    let tmpDisplayMode = "normal";

    if (_size.width < 860) {
      tmpDisplayMode = "mobile";
    }

    displayModeSet((_prev) => {
      return tmpDisplayMode;
    });
  };

  useLayoutEffect(() => {
    function updateSize() {
      const newSize = { width: window.innerWidth, height: window.innerHeight };

      windowSizeSet((_prev) => {
        return newSize;
      });
      updateDisplayMode(newSize);
    }

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <DeviceDisplayContext.Provider
      value={{
        displayMode,
        windowSize,
      }}
    >
      {children}
    </DeviceDisplayContext.Provider>
  );
};

export default DeviceDisplayContext;
