//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useDeviceDisplay from "../../../hooks/useDeviceDisplay.js";

//& component imports =========================================================================
import MenuItem from "./MenuItem.jsx";

//& component =================================================================================
const Menu = () => {
  const { displayMode } = useDeviceDisplay();

  //& rendering ===============================================================================
  return (
    <div
      className={`flexRow justifySpaceAround gapLarge ${
        displayMode === "mobile" ? "mobile-hsop-" : ""
      }menu`}
    >
      <MenuItem label="NEWS" code="news" />
      <MenuItem label="GIGS" code="gigs" />
      <MenuItem label="AUFNAHMEN" code="aufnahmen" />
    </div>
  );
};

export default Menu;
