//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& resource imports ==========================================================================
import ClaimNameOnly from "../../img/static/claim/claimNameOnly.png";
import Pressebild1 from "../../img/static/promo/Hardt Stompers Pressebild 1.jpg";
import Pressebild1_small from "../../img/static/promo/Hardt Stompers Pressebild 1_small.jpg";

//& component =================================================================================
const HelloText = () => {
  const { BASE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="bubble textCenter hellotext contentWidth">
      <div className="flexRow alignCenter">
        <img
          src={Pressebild1_small}
          alt="Pressebild1"
          className="hellotext-image"
          onClick={() => {
            BASE.onImageClick(Pressebild1);
          }}
        />
        <div
          className="hellotext-textblock hellotext-text-1"
          dangerouslySetInnerHTML={{
            __html: RESOURCES.TEXTS.helloText1HTML.replace(
              "YEAR",
              new Date().getFullYear() - 1980
            ),
          }}
        />
      </div>
      <br />
      <div
        className="hellotext-textblock hellotext-text-2"
        dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.helloText2HTML }}
      />
      <br />
      Ihre junggebliebenen
      <br />
      <br />
      <img
        onClick={() => {
          window.location.href = "/hsop";
        }}
        className="hellotext-name"
        src={ClaimNameOnly}
        alt="ClaimNameOnly"
      />
    </div>
  );
};

export default HelloText;
