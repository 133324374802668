//& react & mui imports =======================================================================
import React from "react"

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import VeranstalterDocument from "../Veranstalter/VeranstalterDocument.jsx";

//& component =================================================================================
const VeranstalterMobile = () => {
  const { RESOURCES} = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn alignCenter gapSmall">
      {RESOURCES.VERANSTALTERDOCUMENTS.map((document, index) => {
        return (
          <VeranstalterDocument
            key={index}
            name={document.name}
            thumbnail={document.thumbnail}
            thumbnailWidth={80}
            text={document.text}
            type={document.type}
            width="20rem"
            height="15rem"
          />
        );
      })}
    </div>
  )
};

export default VeranstalterMobile;
