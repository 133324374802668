//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import StartMobile from "./StartMobile.jsx";
import BandMobile from "./BandMobile.jsx";
import GalerieMobile from "./GalerieMobile.jsx";
import DiskographieMobile from "./DiskographieMobile.jsx";
import VeranstalterMobile from "./VeranstalterMobile.jsx";
import KontaktMobile from "./KontaktMobile.jsx";
import ImpressumMobile from "./ImpressumMobile.jsx";
import DatenschutzerklaerungMobile from "./DatenschutzerklaerungMobile.jsx";

//& component =================================================================================
const ContentMobile = () => {
  const { BASE, MOBILE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="mobile-content" onClick={MOBILE.onClick}>
      {BASE.activeMenuItem === "start" && <StartMobile />}
      {BASE.activeMenuItem === "band" && <BandMobile />}
      {BASE.activeMenuItem === "galerie" && <GalerieMobile />}
      {BASE.activeMenuItem === "diskographie" && <DiskographieMobile />}
      {BASE.activeMenuItem === "veranstalter" && <VeranstalterMobile />}
      {BASE.activeMenuItem === "kontakt" && <KontaktMobile />}
      {BASE.activeMenuItem === "impressum" && <ImpressumMobile />}
      {BASE.activeMenuItem === "datenschutzerklaerung" && (
        <DatenschutzerklaerungMobile />
      )}
    </div>
  );
};

export default ContentMobile;
