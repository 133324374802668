//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const Diskografie = () => {
  const { BASE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="bubble contentWidth flexColumn discography">
      <br />
      <div
        className="discography-cd"
        dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.cdPromoTextHTML }}
      ></div>
      <div className="divider"></div>

      {RESOURCES.DISCOGRAPHY.map((_cd, _index) => (
        <div key={_index} className="discography-cd">
          <div className="flexRow gapMedium alignCenter">
            <img
              className="discography-cdImage"
              src={_cd.image}
              alt={_cd.alt}
              onClick={() => {
                BASE.onImageClick(_cd.image);
              }}
            />
            <div className="flexColumn gapSmall">
              <h3>
                {_cd.year}: {_cd.name}
              </h3>
              {_index === 0 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: RESOURCES.TEXTS.cd4DescriptionTextHTML,
                  }}
                />
              )}
            </div>
          </div>
          {_index !== RESOURCES.DISCOGRAPHY.length - 1 && (
            <div key={`cd_${_index}_divider`} className="divider" />
          )}
        </div>
      ))}
    </div>
  );
};

export default Diskografie;
