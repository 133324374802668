//& react & mui imports =======================================================================
import React from "react"

//& hook imports ==============================================================================
import useFrontend from "../../../hooks/useFrontend.js";

//& component imports =========================================================================
import BaseSocialMedia from "../../BaseControls/BaseSocialMedia.jsx"

//& component =================================================================================
const MenuItemMobile = ({code, label}) => {
  const { MOBILE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexRow alignCenter gapSmall mobile-menu-item border" onClick={() => {
      if (code === "youtube" || code === "facebook") {
        var win = window.open(RESOURCES.URLS[code], '_blank');
        win.focus();
        MOBILE.isMobileMenuOpenSet(false)
      } else {

      MOBILE.onMenuItemClick(code)
    }
    }}>
      {label}
      {code === "youtube" && (<BaseSocialMedia elementSize={30} network="youtube" url={RESOURCES.URLS.youtube} />)}
      {code === "facebook" && (<BaseSocialMedia elementSize={30} network="facebook" url={RESOURCES.URLS.facebook} />)}
    </div>
  )
};

export default MenuItemMobile;
