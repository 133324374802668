//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../../hooks/useFrontend.js";

//& resource imports ==========================================================================
import ClaimNameOnly from "../../../img/static/claim/claimNameOnly.png";
import Pressebild1 from "../../../img/static/promo/Hardt Stompers Pressebild 1.jpg";
import Pressebild1_small from "../../../img/static/promo/Hardt Stompers Pressebild 1_small.jpg";

//& component =================================================================================
const HelloTextMobile = () => {
  const { BASE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="mobile-bubble flexColumn gapSmall alignCenter textCenter mobile-hellotext">
      <img
        src={Pressebild1_small}
        alt="Pressebild1"
        className="hellotext-image"
        onClick={() => {
          BASE.onImageClick(Pressebild1);
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: RESOURCES.TEXTS.helloText1HTML.replace(
            "YEAR",
            new Date().getFullYear() - 1980
          ),
        }}
      />
      <div
        dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.helloText2HTML }}
      />
      Ihre junggebliebenen
      <br />
      <img
        onClick={() => {
          window.location.href = "/hsop";
        }}
        className="hellotext-name"
        src={ClaimNameOnly}
        alt="ClaimNameOnly"
      />
    </div>
  );
};

export default HelloTextMobile;
