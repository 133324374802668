//& react & mui imports =======================================================================
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin";

//& component imports =========================================================================
import BaseButton from "../../BaseControls/BaseButton";

//& component =================================================================================
const ConfirmModal = ({ open }) => {
  const { CONFIRMMODAL } = useAdmin();

  //& rendering ===============================================================================
  return (
    <Modal open={open}>
      <Box className="flexColumn gapMedium confirmModal">
        <div>
          <font size="4.5">
            <b>{CONFIRMMODAL.data.text}</b>
          </font>
        </div>
        <div className="flexRow gapLarge justifyEnd">
          <BaseButton
            onClick={CONFIRMMODAL.data.onDeleteCancel}
            caption="Nein"
          />
          <BaseButton
            onClick={() => {
              CONFIRMMODAL.data.onDeleteConfirm(CONFIRMMODAL.data.entity);
            }}
            caption="Ja"
          />
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
