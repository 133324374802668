//& react & mui imports =======================================================================
import React from "react";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin";
import useDeviceDisplay from "../../../hooks/useDeviceDisplay";

//& component imports =========================================================================
import BaseButton from "../../BaseControls/BaseButton";

//& component =================================================================================
const Audio = ({ file }) => {
  const { AUFNAHMEN } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  const isLoading = AUFNAHMEN.audioIsLoadingName === file.name;
  const audioSource = Object.keys(AUFNAHMEN.audioSources).find(
    (_key) => _key === file.name
  )
    ? AUFNAHMEN.audioSources[file.name]
    : null;

  const classNames = {
    aufnahmeWrapper:
      displayMode === "mobile" ? "" : "flexRow gapSmall aufnahme alignCenter",
    aufnahmeTitel: displayMode === "mobile" ? "" : "aufnahme-titel",
    aufnahmePlayer:
      displayMode === "mobile"
        ? "mobile-aufnahme-player"
        : "aufnahme-audioPlayer",
  };

  //& rendering ===============================================================================
  return (
    <div className={classNames.aufnahmeWrapper}>
      <div className={classNames.aufnahmeTitel}>{file.name}</div>
      <div
        className={`flexRow gapSmall alignCenter ${classNames.aufnahmePlayer}`}
      >
        {audioSource && (
          <Tooltip title="Aufnahme herunterladen">
            <span>
              <IconButton
                onClick={() => {
                  AUFNAHMEN.onClickDownload(file.name);
                }}
              >
                <DownloadForOfflineOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {isLoading && <div>Lädt Aufnahme...</div>}

        {audioSource && (
          <audio
            controls
            contextMenu="none"
            controlsList="nodownload nocontextmenu"
            src={audioSource}
            onPlay={AUFNAHMEN.onAudioPlay}
            className="aufnahme-audioPlayer"
          />
        )}

        {!isLoading && !audioSource && (
          <BaseButton
            className="aufnahme-loadButton"
            onClick={() => {
              AUFNAHMEN.onClickLoad(file);
            }}
            caption="Aufnahme laden"
            width="9rem"
          />
        )}
      </div>
    </div>
  );
};

export default Audio;
