//& react & mui imports =======================================================================
import React from "react"
import TextField from "@mui/material/TextField";
import BaseButton from "../BaseControls/BaseButton.jsx";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const KontaktMobile = () => {
  const { RESOURCES} = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapSmall">
        <div className="bubble textCenter"
          dangerouslySetInnerHTML={{ __html: RESOURCES.TEXTS.kontaktText1HTML }}
         />
         <div className="flexColumn gapSmall bubble">
         {RESOURCES.CREWMEMBERS
          .filter((_member) => _member.contactUser)
          .map((_member, _index) => {
            return (
              <div key={_index} className="flexRow gapSmall alignCenter justifySpaceBetween">
                <img
                  src={_member.imageFaceOnly}
                  alt={_member.name}
                  className="kontakt-telefon-image"
                />
                <div className="flexColumn">
                  <div className="kontakt-data">
                    {_member.name}
                    <br />
                    <a href={`tel:+49${_member.phone.slice(1).trim()}`}>{_member.phone}</a>
                  </div>
                </div>
                <img
            className=""
            height="25px"
            src={RESOURCES.IMAGES.PhoneIcon}
            alt={RESOURCES.TEXTS.phone}
          />
              </div>
            );
          })}
         </div>

          <div className="bubble flexColumn alignCenter gapSmall">  

            <div className="flexRow alignCenter">
            <img
            className="kontakt-telefon-mailIcon"
            src={RESOURCES.IMAGES.MailIcon}
            alt={RESOURCES.TEXTS.message}
          />
          <div className="mobile-kontakt-message-placeholder">{RESOURCES.TEXTS.messagePlaceholder.replace("...", ":")}</div>

            </div>


          <TextField
            className="mobile-kontakt-message-textfield"
            id="kontakt"
            size="small"
            variant="outlined"
            multiline
            rows={12}
          />
          <BaseButton
            variant="contained"
            color="primary"
            caption={RESOURCES.TEXTS.send}
          />
        </div>

         



    </div>
  )
};

export default KontaktMobile;
