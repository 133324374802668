//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import MenuItem from "./MenuItem.jsx";

//& component =================================================================================
const Menu = () => {
  //& rendering ===============================================================================
  return (
    <div className="flexRow justifySpaceBetween gapLarge menu">
      <MenuItem label="START" code="start" />
      <MenuItem label="DIE BAND" code="band" />
      <MenuItem label="GALERIE" code="galerie" />
      <MenuItem label="DISKOGRAFIE" code="diskografie" />
      <MenuItem label="VERANSTALTER" code="veranstalter" />
      <MenuItem label="KONTAKT" code="kontakt" />
    </div>
  );
};

export default Menu;
