//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useAdmin from "../../hooks/useAdmin";
import useDeviceDisplay from "../../hooks/useDeviceDisplay";

//& resource imports ==========================================================================
import Claim from "../../img/static/claim/claim.png";
import Boat from "../../img/static/background/background_boat2.png";

//& component =================================================================================
const Header = () => {
  const { BASE } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn header">
      <div className="flexRow alignCenter justifySpaceBetween">
        <img
          src={Claim}
          className="claim"
          alt="Start"
          width="300"
          onClick={BASE.onClickClaim}
        />
        {displayMode === "normal" && (
          <img src={Boat} className="boat header-boat" alt="steamboat" />
        )}
      </div>
    </div>
  );
};

export default Header;
