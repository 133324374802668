//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useAdmin from "../../hooks/useAdmin";

//& component imports =========================================================================
import Header from "./Header.jsx";
import Menu from "./Menu/Menu.jsx";
import Content from "./Content.jsx";
import ConfirmModal from "./Modals/ConfirmModal.jsx";

//& style imports =============================================================================
import "../../styles/hsop.css";

//& component =================================================================================
const HSOP = () => {
  const { CONFIRMMODAL } = useAdmin();

  //& rendering ===============================================================================
  return (
    <>
      {CONFIRMMODAL.data !== null && (
        <ConfirmModal open={CONFIRMMODAL.data !== null} />
      )}
      <div className="flexColumn">
        <Header />
        <Menu />
        <Content />
      </div>
    </>
  );
};

export default HSOP;
