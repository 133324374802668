import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";

const PrivateRoute = () => {
  const { checkAuth, token } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  if (!token) return <Navigate to="/login" />;
  return <Outlet />;
};

export default PrivateRoute;
