//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import BaseIconButton from "../BaseControls/BaseIconButton.jsx";
import BaseSocialMedia from "../BaseControls/BaseSocialMedia.jsx";

//& component =================================================================================
const Galerie = () => {
  const { BASE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn alignCenter">
      <div className="gallery-polaroid-container">
        <div
          className="polaroid gallery-polaroid-left"
          polaroid-caption={`© Benjamin Reiner`}
        >
          <img
            src={RESOURCES.IMAGES.Pressebild1_small}
            alt="Image1"
            width="350"
            onClick={() => {
              BASE.modalDataSet({
                type: "image",
                data: RESOURCES.IMAGES.Pressebild1,
              });
            }}
          />
        </div>

        <div
          className="polaroid gallery-polaroid-right"
          polaroid-caption={`© Benjamin Reiner`}
        >
          <img
            src={RESOURCES.IMAGES.Pressebild2_small}
            alt="Image2"
            width="350"
            onClick={() => {
              BASE.modalDataSet({
                type: "image",
                data: RESOURCES.IMAGES.Pressebild2,
              });
            }}
          />
        </div>
      </div>

      <div className="flexRow gapLarge">
        <div className="polaroid" polaroid-caption={`© Heike Schmit-Scheub`}>
          <img
            src={RESOURCES.IMAGES.Galerie_Marbach2024_1}
            width="250"
            alt="Image3"
            onClick={() => {
              BASE.modalDataSet({
                type: "image",
                data: RESOURCES.IMAGES.Galerie_Marbach2024_1,
              });
            }}
          />
        </div>
        <div className="polaroid" polaroid-caption={`© Heike Schmit-Scheub`}>
          <img
            src={RESOURCES.IMAGES.Galerie_Marbach2024_3}
            width="250"
            alt="Image3"
            onClick={() => {
              BASE.modalDataSet({
                type: "image",
                data: RESOURCES.IMAGES.Galerie_Marbach2024_3,
              });
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <div className="flexColumn gapSmall alignCenter">
        <div className="bubble gallery-entry">
          <div className="flexRow gapSmall alignCenter">
            <BaseIconButton
              iconType="openIn"
              onClick={() => {
                BASE.modalDataSet({
                  type: "image",
                  data: RESOURCES.IMAGES.Presse_Gaeubote,
                });
              }}
            />
            Bericht aus dem Gäuboten zum Auftritt in Gärtringen
          </div>
        </div>

        <div className="bubble gallery-entry">
          <div className="flexRow gapSmall alignCenter">
            <BaseSocialMedia
              elementSize={40}
              network="youtube"
              url={RESOURCES.URLS.youtube}
            />
            Die HARDT STOMPERS auf YouTube
            <br />
            <br />
            Mehr Videos von uns auf YouTube finden Sie mit dem Suchbegriff
            "Hardt Stompers"
          </div>
        </div>
        <div className="bubble gallery-entry">
          <div className="flexRow gapSmall alignCenter">
            <BaseSocialMedia
              elementSize={40}
              network="facebook"
              url={RESOURCES.URLS.facebook}
            />
            Die HARDT STOMPERS auf Facebook
          </div>
        </div>

        <div className="bubble gallery-entry">
          <div className="flexRow gapSmall alignCenter">
            <BaseIconButton
              iconType="openIn"
              onClick={() => {
                BASE.modalDataSet({
                  type: "text",
                  data: RESOURCES.TEXTS.schoeneckerTextHTML,
                });
              }}
            />
            Bericht zur aktuellen CD "When My Dreamboat Comes Home" -<br />
            von Dr. Helmut Schönecker
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galerie;
