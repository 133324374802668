//& react & mui imports =======================================================================
import React from "react";

//& library imports ===========================================================================
import { SocialIcon } from "react-social-icons";

//& component =================================================================================
const BaseSocialMedia = ({ elementSize, network, url }) => {
  //& rendering ===============================================================================
  return (
    <SocialIcon
      target="_blank"
      style={{ height: elementSize, width: elementSize }}
      network={network}
      url={url}
    />
  );
};

export default BaseSocialMedia;
