//& react & mui imports =======================================================================
import React from "react"
import ListIcon from '@mui/icons-material/List';

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const HeaderMobile = () => {
    const { MOBILE, RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexRow mobile-header justifySpaceBetween">
      <div className="flexColumn mobile-header-claim" onClick={MOBILE.onClick}>
        <img src={RESOURCES.IMAGES.HS} width="100%"/>
        <img src={RESOURCES.IMAGES.TJ} width="50%"/>
      </div>
      <div className="flexColumn alignCenter justifyCenter mobile-header-menu" onClick={MOBILE.onMenuClick}>
        <div><b>MENÜ</b></div>
        <div className="mobile-header-menu-icon">
          <ListIcon />
        </div>
        
      </div>

      
    </div>
  )
};

export default HeaderMobile;
