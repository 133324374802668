//& react & mui imports =======================================================================
import React from "react";
import Button from "@mui/material/Button";

//& component =================================================================================
const BaseButton = ({
  color = "primary",
  caption = "na",
  className = "",
  disabled = false,
  height = "2rem",
  onClick = () => {},
  variant = "outlined",
  minWidth = "7rem",
  width = "7rem",
  type = null,
}) => {
  //& rendering ===============================================================================
  return (
    <Button
      className={className}
      color={color}
      disabled={disabled}
      onClick={onClick}
      style={{
        height,
        minWidth,
        width,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "none",
      }}
      variant={variant}
      type={type}
    >
      {caption}
    </Button>
  );
};

export default BaseButton;
