//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import SocialMedia from "./SocialMedia.jsx";

//& hook imports ==============================================================================
import useFrontend from "../hooks/useFrontend.js";

//& resource imports ==========================================================================
import Claim from "../img/static/claim/claim.png";
import Boat from "../img/static/background/background_boat2.png";

//& component =================================================================================
const Header = () => {
  const { BASE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn header">
      <div className="flexRow alignCenter justifySpaceBetween">
        <img className="boat" src={Boat} alt={"boat"} />
        <img
          src={Claim}
          className="claim"
          alt="Start"
          width="300"
          onClick={() => {
            BASE.activeMenuItemSet("start");
          }}
        />
        <SocialMedia elementSize={40} />
      </div>
    </div>
  );
};

export default Header;
