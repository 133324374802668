//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin.js";
import useDeviceDisplay from "../../../hooks/useDeviceDisplay.js";

//& component imports =========================================================================
import AufnahmenFilterSelect from "./AufnahmenFilterSelect.jsx";
import Audio from "./Audio.jsx";
import ErrorAlert from "../ErrorAlert.jsx";

//& component =================================================================================
const Aufnahmen = () => {
  const { AUFNAHMEN, BASE } = useAdmin();
  const { displayMode } = useDeviceDisplay();

  const className =
    displayMode === "mobile"
      ? "flexColumn gapSmall mobile-bubble mobile-aufnahmen-wrapper"
      : "bubble contentWidth";

  //& rendering ===============================================================================
  if (
    AUFNAHMEN.aufnahmen === null ||
    AUFNAHMEN.aufnahmenFilter === null ||
    AUFNAHMEN.aufnahmenFiltered === null
  ) {
    return <div>Lädt Aufnahmen...</div>;
  }

  return (
    <div className={className}>
      {BASE.error && <ErrorAlert />}
      <AufnahmenFilterSelect />

      {AUFNAHMEN.aufnahmenFiltered.map((_file) => {
        return (
          <div key={_file.name}>
            <Audio file={_file} />
          </div>
        );
      })}
    </div>
  );
};

export default Aufnahmen;
