//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component imports =========================================================================
import NewsEntry from "./NewsEntry.jsx";

//& component =================================================================================
const NewsEntries = () => {
  const { BASE, NEWS } = useFrontend();

  //& rendering ===============================================================================
  if (!NEWS.newsFiltered || !BASE.settings || NEWS.newsFiltered.length === 0) {
    return null;
  }

  return (
    <div className="flexColumn gapSmall bubble contentWidth">
      <div className="textHeader textCenter">Neues und Bemerkenswertes</div>
      {NEWS.newsFiltered.map((_entry) => (
        <NewsEntry key={_entry.id} entry={_entry} />
      ))}
    </div>
  );
};

export default NewsEntries;
