//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const BandMember = ({ member }) => {
  const { BASE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapMedium bubble bandmember">
      <div className="flexRow alignCenter justifySpaceBetween bandmember-header">
        <div className="flexColumn">
          <font size="4">
            <b>{member.name}</b>
          </font>
          <div>{member.instrument}</div>
        </div>

        <div className="flexRow gapSmall">
          <img
            src={member.imageSmall}
            alt={member.name}
            width="100"
            className="bandmember-image"
            onClick={() => {
              BASE.onImageClick(member.image);
            }}
          />
        </div>
      </div>

      <div className="bandmember-text">{member.text}</div>
    </div>
  );
};

export default BandMember;
