const hasWWW = window.location.hostname.startsWith("www.");

const envProps = {
  development: {
    client: {
      protocol: "http",
      domain: "localhost",
      port: "3000",
    },
    server: {
      protocol: "http",
      domain: "localhost",
      port: "9488",
    },
  },
  production: {
    client: {
      protocol: "https",
      domain: "hardtstomperstemporary.cloud",
      port: null,
    },
    server: {
      protocol: "https",
      domain: "api.hardtstomperstemporary.cloud",
      port: null,
    },
  },
};

const detectedEnv =
  window.location.hostname.replace("www.", "") ===
  envProps.production.client.domain
    ? "production"
    : "development";

const props = {
  client: {
    protocol: envProps[detectedEnv].client.protocol,
    domain: envProps[detectedEnv].client.domain,
    port: envProps[detectedEnv].client.port,
    url: `${envProps[detectedEnv].client.protocol}://${hasWWW ? "www." : ""}${
      envProps[detectedEnv].client.domain
    }${
      envProps[detectedEnv].client.port
        ? ":" + envProps[detectedEnv].client.port
        : ""
    }`,
  },
  server: {
    protocol: envProps[detectedEnv].server.protocol,
    domain: envProps[detectedEnv].server.domain,
    port: envProps[detectedEnv].server.port,
    url: `${envProps[detectedEnv].server.protocol}://${
      envProps[detectedEnv].server.domain
    }${
      envProps[detectedEnv].server.port
        ? ":" + envProps[detectedEnv].server.port
        : ""
    }`,
  },
};

props.allowedOrigins = [
  `${props.client.protocol}://${props.client.domain}`,
  `${props.client.protocol}://www.${props.client.domain}`,
  `${props.client.protocol}://${props.client.domain}:${props.client.port}`,
  `${props.client.protocol}://www.${props.client.domain}:${props.client.port}`,
];

module.exports = props;
