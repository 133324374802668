import BR from "./img/static/crew/members/br.jpg";
import BR_small from "./img/static/crew/members/br_small.jpg";
import CD0 from "./img/static/discography/cd0.jpg";
import CD1 from "./img/static/discography/cd1.jpg";
import CD2 from "./img/static/discography/cd2.jpg";
import CD3 from "./img/static/discography/cd3.png";
import CD4 from "./img/static/discography/cd4.jpg";
import DocumentIcon from "./img/static/misc/documentIcon.png";
import Galerie_Marbach2024_1 from "./img/static/gallery/Galerie_Marbach2024_1.jpg";
import Galerie_Marbach2024_2 from "./img/static/gallery/Galerie_Marbach2024_2.jpg";
import Galerie_Marbach2024_3 from "./img/static/gallery/Galerie_Marbach2024_3.jpg";
import Galerie_Marbach2024_4 from "./img/static/gallery/Galerie_Marbach2024_4.jpg";
import GF from "./img/static/crew/members/gf.jpg";
import GF_faceOnly from "./img/static/crew/members/gf_faceonly.jpg";
import GF_small from "./img/static/crew/members/gf_small.jpg";
import HS from "./img/static/claim/HS.png";
import KS from "./img/static/crew/members/ks.jpg";
import KS_small from "./img/static/crew/members/ks_small.jpg";
import KV from "./img/static/crew/members/kv.jpg";
import KV_small from "./img/static/crew/members/kv_small.jpg";
import Logo from "./img/static/promo/Hardt Stompers Logo.png";
import Logo_small from "./img/static/promo/Hardt Stompers Logo_small.png";
import MailIcon from "./img/static/icons/mail.png";
import PhoneIcon from "./img/static/icons/phone.png";
import Plakatvordruck from "./img/static/promo/Hardt Stompers Plakatvordruck.jpg";
import Plakatvordruck_small from "./img/static/promo/Hardt Stompers Plakatvordruck_small.jpg";
import PlakatvordruckSW from "./img/static/promo/Hardt Stompers Plakatvordruck sw.jpg";
import PlakatvordruckSW_small from "./img/static/promo/Hardt Stompers Plakatvordruck sw_small.jpg";
import Presse_Gaeubote from "./img/static/gallery/Presse_Gaeubote.png";
import Pressebild1 from "./img/static/promo/Hardt Stompers Pressebild 1.jpg";
import Pressebild1_small from "./img/static/promo/Hardt Stompers Pressebild 1_small.jpg";
import Pressebild2 from "./img/static/promo/Hardt Stompers Pressebild 2.jpg";
import Pressebild2_small from "./img/static/promo/Hardt Stompers Pressebild 2_small.jpg";
import Pressetext from "./img/static/promo/Hardt Stompers Pressetext.pdf";
import TechnicalRider from "./img/static/promo/Hardt Stompers Technical Rider.pdf";
import TJ from "./img/static/claim/TJ.png";
import WA from "./img/static/crew/members/wa.jpg";
import WA_small from "./img/static/crew/members/wa_small.jpg";
import WS from "./img/static/crew/members/ws.jpg";
import WS_faceOnly from "./img/static/crew/members/ws_faceonly.jpg";
import WS_small from "./img/static/crew/members/ws_small.jpg";

const RESOURCES = {
  CREWMEMBERS: [
    {
      name: "Günter Friedhelm",
      image: GF,
      imageSmall: GF_small,
      imageFaceOnly: GF_faceOnly,
      contactUser: true,
      instrument: "Kornett, Gesang",
      phone: "01577 292 16 41",
      email: "g.friedhelm@yahoo.de",
      text: "Als er Satchmo das erste Mal hörte, wusste er wo die musikalische Reise hingehen soll. Seine Adaption von Louis Armstrongs Reibeisenstimme wird durch passendes Kornett Geschmetter und launigen Ansagen eher zweifelhaften Wahrheitsgehalts ergänzt.",
    },
    {
      name: "Wolfgang Schenk",
      image: WS,
      imageSmall: WS_small,
      imageFaceOnly: WS_faceOnly,
      contactUser: true,
      instrument: "Posaune, Gesang",
      phone: "0152 0987 77 77",
      email: "wg.schenk@yahoo.com",
      text: "Er als ein echtes Urgestein der „Ländle-Jazzer“ changiert zwischen Chris Barber und Kid Ory, immer auf der Suche nach der perfekten Posaune um mit ausdrucksstarkem Spiel seinem Instrument die Bedeutung zukommen zu lassen, welches ihm in jeder Hinsicht gebührt.",
    },
    {
      name: "Wolfgang Albrecht",
      image: WA,
      imageSmall: WA_small,
      contactUser: false,
      instrument: "Klarinette, Saxophone",
      phone: "",
      text: "Mit 10 Jahren wollte er Jazztrompeter werden und bekam stattdessen eine Klarinette. Inspiriert von Jazz-Legenden wie George Lewis, Sidney Bechet bis hin zu Bob Wilber oder Peanuz Hucko und viele andere mehr, pflegt er seit 1972 mit Leidenschaft den traditionellen Jazz und greift zuweilen auch zum Alt- oder Sopransaxofon.",
    },
    {
      name: "Kurt Schlaf",
      image: KS,
      imageSmall: KS_small,
      contactUser: false,
      instrument: "Banjo, Gitarre",
      phone: "",
      text: "Auszeichnend für Kurt seine akkurate Rhythmuskonstanz und Griffsicherheit auf Banjo und Gitarre. Wir bezeichnen es als Schweizer Präzision, zuverlässig routiniert aufbereitet durch seine oberbayrischen Hände.",
    },
    {
      name: "Benny Reiner",
      image: BR,
      imageSmall: BR_small,
      phone: "",
      contactUser: false,
      instrument: "Sousaphon, Kontrabass",
      text: "Zusammen mit Kurt und Karel ist er für die erlaubte Geschwindigkeit zuständig, spielt aber auch manchen Streich hinter den Bläser-Rücken. Buchstäblich der Benjamin der Band, ist er folglich unser Webseiten-Beauftragter.",
    },
    {
      name: "Karel Vlach",
      image: KV,
      imageSmall: KV_small,
      contactUser: false,
      instrument: "Schlagzeug",
      phone: "",
      text: "Zu goldenen Stuttgarter Königshofzeiten blieb Karel nach einem dortigen Gastspiel als Schlagzeuger in Deutschland hängen. Viele Jahre agierte er in einer der renommiertesten Gala-Tanz-Bigbands und ist dadurch mit allen stilistischen Wassern gewaschen.",
    },
  ],
  DISCOGRAPHY: [
    {
      year: 2020,
      name: "When My Dreamboat Comes Home",
      image: CD4,
    },
    {
      year: 2012,
      name: "Dixieland Jubilee",
      image: CD1,
    },
    {
      year: 2006,
      name: "Silver Jubilee",
      image: CD2,
    },
    {
      year: 2000,
      name: "Live In Concert - ausverkauft",
      image: CD3,
    },
    {
      year: 1987,
      name: "Hardt Stompers auf Vinyl - ausverkauft",
      image: CD0,
    },
  ],
  IMAGES: {
    BR,
    BR_small,
    CD1,
    CD2,
    CD3,
    CD4,
    DocumentIcon,
    Galerie_Marbach2024_1,
    Galerie_Marbach2024_2,
    Galerie_Marbach2024_3,
    Galerie_Marbach2024_4,
    GF,
    GF_faceOnly,
    GF_small,
    HS,
    KS,
    KS_small,
    KV,
    KV_small,
    Logo,
    Logo_small,
    MailIcon,
    PhoneIcon,
    Plakatvordruck,
    Plakatvordruck_small,
    PlakatvordruckSW,
    PlakatvordruckSW_small,
    Presse_Gaeubote,
    Pressebild1,
    Pressebild1_small,
    Pressebild2,
    Pressebild2_small,
    Pressetext,
    TechnicalRider,
    TJ,
    WA,
    WA_small,
    WS,
    WS_faceOnly,
    WS_small,
  },
  TEXTS: {
    messagePlaceholder: "Ihre Nachricht an uns...",
    send: "Senden",
    datenschutzerklaerungHTML: ` <font size="5">
        <b>Datenschutzerklärung</b>
      </font>
      <p>
        Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden
        von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der
        Bereitstellung eines funktionsfähigen und nutzerfreundlichen
        Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
        Leistungen, verarbeitet.
      </p>
      <p>
        Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der
        Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als
        „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten, wie das Erheben, das Erfassen, die
        Organisation, das Ordnen, die Speicherung, die Anpassung oder
        Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung
        durch Übermittlung, Verbreitung oder eine andere Form der
        Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
        das Löschen oder die Vernichtung.
      </p>
      <p>
        Mit der nachfolgenden Datenschutzerklärung informieren wir Sie
        insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der
        Verarbeitung personenbezogener Daten, soweit wir entweder allein oder
        gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
        entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu
        Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
        eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum
        eigener Verantwortung verarbeiten.
      </p>
      <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
      <p>
        I. Informationen über uns als Verantwortliche
        <br />
        II. Rechte der Nutzer und Betroffenen
        <br />
        III. Informationen zur Datenverarbeitung
      </p>
      <h3 className="wp-block-heading">
        I. Informationen über uns als Verantwortliche
      </h3>
      <p>
        Verantwortlicher Anbieter dieses Internetauftritts im
        datenschutzrechtlichen Sinne ist:
      </p>
      Hardt Stompers GbR
      <br />
      Wolfgang Schenk
      <br />
      Steigerwaldstrasse 1
      <br />
      70469 Stuttgart
      <p>
        Telefon: +49 152 0987 77 77
        <br />
        E-Mail: wg.schenk at yahoo.com
      </p>
      <p>Datenschutzbeauftragter beim Anbieter ist:</p>
      Benjamin Reiner
      <br />
      E-Mail: tuba0815 at outlook.de
      <h3 className="wp-block-heading">
        II. Rechte der Nutzer und Betroffenen
      </h3>
      <p>
        Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung
        haben die Nutzer und Betroffenen das Recht
      </p>
      <ul className="wp-block-list">
        <li>
          auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf
          Auskunft über die verarbeiteten Daten, auf weitere Informationen über
          die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15
          DSGVO);
        </li>
  
        <li>
          auf Berichtigung oder Vervollständigung unrichtiger bzw.
          unvollständiger Daten (vgl. auch Art. 16 DSGVO);
        </li>
  
        <li>
          auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art.
          17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß
          Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der
          Verarbeitung nach Maßgabe von Art. 18 DSGVO;
        </li>
  
        <li>
          auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten
          und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche
          (vgl. auch Art. 20 DSGVO);
        </li>
  
        <li>
          auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht
          sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß
          gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch
          Art. 77 DSGVO).
        </li>
      </ul>
      <p>
        Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen
        gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede
        Berichtigung oder Löschung von Daten oder die Einschränkung der
        Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt,
        zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
        Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand
        verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft
        über diese Empfänger.
      </p>
      <p>
        <strong>
          Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das
          Recht auf Widerspruch gegen die künftige Verarbeitung der sie
          betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe
          von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist
          ein Widerspruch gegen die Datenverarbeitung zum Zwecke der
          Direktwerbung statthaft.
        </strong>
      </p>
      <h3 className="wp-block-heading">
        III. Informationen zur Datenverarbeitung
      </h3>
      <p>
        Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden
        gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der
        Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen und nachfolgend keine anderslautenden Angaben zu
        einzelnen Verarbeitungsverfahren gemacht werden.
      </p>
      <h4 className="jet-listing-dynamic-field__content">Cookies</h4>
      <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
      <p>
        Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind
        kleine Textdateien oder andere Speichertechnologien, die durch den von
        Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und
        gespeichert werden. Durch diese Cookies werden im individuellen Umfang
        bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder
        Standortdaten oder Ihre IP-Adresse, verarbeitet.
      </p>
      <p>
        Durch diese Verarbeitung wird unser Internetauftritt
        benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw.
        die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen
        oder das Angebot einer Warenkorbfunktion ermöglicht.
      </p>
      <p>
        Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO,
        sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung
        verarbeitet werden.
      </p>
      <p>
        Falls die Verarbeitung nicht der Vertragsanbahnung oder
        Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der
        Verbesserung der Funktionalität unseres Internetauftritts.
        Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
      </p>
      <p>
        Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies
        gelöscht.
      </p>
      <h5>b) Drittanbieter-Cookies</h5>
      <p>
        Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von
        Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse
        oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten,
        verwendet.
      </p>
      <p>
        Die Einzelheiten hierzu, insbesondere zu den Zwecken und den
        Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies,
        entnehmen Sie bitte den nachfolgenden Informationen.
      </p>
      <h5>c) Beseitigungsmöglichkeit</h5>
      <p>
        Sie können die Installation der Cookies durch eine Einstellung Ihres
        Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie
        bereits gespeicherte Cookies jederzeit löschen. Die hierfür
        erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret
        genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die
        Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden
        sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die
        Verarbeitung allerdings nicht über die Einstellungen des Browsers
        unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung
        Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und
        Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen
        benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation
        Ihres Flash-Players oder wenden sich an den Hersteller bzw.
        Benutzer-Support.
      </p>
      <p>
        Sollten Sie die Installation der Cookies verhindern oder einschränken,
        kann dies allerdings dazu führen, dass nicht sämtliche Funktionen
        unseres Internetauftritts vollumfänglich nutzbar sind.
      </p>
      <h4 className="jet-listing-dynamic-field__content">
        Kontaktanfragen / Kontaktmöglichkeit
      </h4>
      <p>
        Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten,
        werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer
        Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und
        Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung
        können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.
      </p>
      <p>
        Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.
      </p>
      <p>
        Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet
        worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen, wie bspw. bei einer sich etwaig anschließenden
        Vertragsabwicklung.
      </p>
      <h4 className="jet-listing-dynamic-field__content">Facebook</h4>
      <p>
        Zur Bewerbung unserer Produkte und Leistungen sowie zur Kommunikation
        mit Interessenten oder Kunden betreiben wir eine Firmenpräsenz auf der
        Plattform Facebook.
      </p>
      <p>
        Auf dieser Social-Media-Plattform sind wir gemeinsam mit der Meta
        Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland,
        verantwortlich.
      </p>
      <p>
        Der Datenschutzbeauftragte von Facebook kann über ein Kontaktformular
        erreicht werden:
      </p>
      <p>
        <a
          href="https://www.facebook.com/help/contact/540977946302970"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://www.facebook.com/help/contact/540977946302970
        </a>
      </p>
      <p>
        Die gemeinsame Verantwortlichkeit haben wir in einer Vereinbarung
        bezüglich der jeweiligen Verpflichtungen im Sinne der DSGVO geregelt.
        Diese Vereinbarung, aus der sich die gegenseitigen Verpflichtungen
        ergeben, ist unter dem folgenden Link abrufbar:
      </p>
      <p>
        <a
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://www.facebook.com/legal/terms/page_controller_addendum
        </a>
      </p>
      <p>
        Rechtsgrundlage für die dadurch erfolgende und nachfolgend
        wiedergegebene Verarbeitung von personenbezogenen Daten ist Art. 6 Abs.
        1 lit. f DSGVO. Unser berechtigtes Interesse besteht an der Analyse, der
        Kommunikation sowie dem Absatz und der Bewerbung unserer Produkte und
        Leistungen.
      </p>
      <p>
        Rechtsgrundlage kann auch eine Einwilligung des Nutzers gemäß Art. 6
        Abs. 1 lit. a DSGVO gegenüber dem Plattformbetreiber sein. Die
        Einwilligung hierzu kann der Nutzer nach Art. 7 Abs. 3 DSGVO jederzeit
        durch eine Mitteilung an den Plattformbetreiber für die Zukunft
        widerrufen.
      </p>
      <p>
        Bei dem Aufruf unseres Onlineauftritts auf der Plattform Facebook werden
        von der Facebook Ireland Ltd. als Betreiberin der Plattform in der EU
        Daten des Nutzers (z.B. persönliche Informationen, IP-Adresse etc.)
        verarbeitet.
      </p>
      <p>
        Diese Daten des Nutzers dienen zu statistischen Informationen über die
        Inanspruchnahme unserer Firmenpräsenz auf Facebook. Die Facebook Ireland
        Ltd. nutzt diese Daten zu Marktforschungs- und Werbezwecken sowie zur
        Erstellung von Profilen der Nutzer. Anhand dieser Profile ist es der
        Facebook Ireland Ltd. beispielsweise möglich, die Nutzer innerhalb und
        außerhalb von Facebook interessenbezogen zu bewerben. Ist der Nutzer zum
        Zeitpunkt des Aufrufes in seinem Account auf Facebook eingeloggt, kann
        die Facebook Ireland Ltd. zudem die Daten mit dem jeweiligen Nutzerkonto
        verknüpfen.
      </p>
      <p>
        Im Falle einer Kontaktaufnahme des Nutzers über Facebook werden die bei
        dieser Gelegenheit eingegebenen personenbezogenen Daten des Nutzers zur
        Bearbeitung der Anfrage genutzt. Die Daten des Nutzers werden bei uns
        gelöscht, sofern die Anfrage des Nutzers abschließend beantwortet wurde
        und keine gesetzlichen Aufbewahrungspflichten, wie z.B. bei einer
        anschließenden Vertragsabwicklung, entgegenstehen.
      </p>
      <p>
        Zur Verarbeitung der Daten werden von der Facebook Ireland Ltd. ggf.
        auch Cookies gesetzt.
      </p>
      <p>
        Sollte der Nutzer mit dieser Verarbeitung nicht einverstanden sein, so
        besteht die Möglichkeit, die Installation der Cookies durch eine
        entsprechende Einstellung des Browsers zu verhindern. Bereits
        gespeicherte Cookies können ebenfalls jederzeit gelöscht werden. Die
        Einstellungen hierzu sind vom jeweiligen Browser abhängig. Bei
        Flash-Cookies lässt sich die Verarbeitung nicht über die Einstellungen
        des Browsers unterbinden, sondern durch die entsprechende Einstellung
        des Flash-Players. Sollte der Nutzer die Installation der Cookies
        verhindern oder einschränken, kann dies dazu führen, dass nicht
        sämtliche Funktionen von Facebook vollumfänglich nutzbar sind.
      </p>
      <p>
        Näheres zu den Verarbeitungstätigkeiten, deren Unterbindung und zur
        Löschung der von Facebook verarbeiteten Daten finden sich in der
        Datenrichtlinie von Facebook:
      </p>
      <p>
        <a
          href="https://www.facebook.com/privacy/explanation"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://www.facebook.com/privacy/explanation
        </a>
      </p>
      <p>
        Es ist nicht ausgeschlossen, dass die Verarbeitung durch die Meta
        Platforms Ireland Limited auch über die Meta Platforms, Inc., 1601
        Willow Road, Menlo Park, California 94025 in den USA erfolgt.
      </p>
      <h4 className="jet-listing-dynamic-field__content">YouTube</h4>
      <p>
        Wir unterhalten bei YouTube eine Onlinepräsenz um unser Unternehmen
        sowie unsere Leistungen zu präsentieren und mit Kunden/Interessenten zu
        kommunizieren. YouTube ist ein Service der Google Ireland Limited,
        Gordon House, Barrow Street, Dublin 4, Irland, ein Tochterunternehmen
        der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA.
      </p>
      <p>
        Insofern weisen wir darauf hin, dass die Möglichkeit besteht, dass Daten
        der Nutzer außerhalb der Europäischen Union, insbesondere in den USA,
        verarbeitet werden. Hierdurch können gesteigerte Risiken für die Nutzer
        insofern bestehen, als dass z.B. der spätere Zugriff auf die Nutzerdaten
        erschwert werden kann. Auch haben wir keinen Zugriff auf diese
        Nutzerdaten. Die Zugriffsmöglichkeit liegt ausschließlich bei YouTube.
      </p>
      <p>Die Datenschutzhinweise von YouTube finden Sie unter</p>
      <p>
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://policies.google.com/privacy
        </a>
      </p>
      <h4 className="jet-listing-dynamic-field__content">facebook</h4>
      <p>
        Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.
      </p>
      <p>
        Datenschutzerklärung:
        <a
          href="https://www.facebook.com/policy.php"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://www.facebook.com/policy.php
        </a>
      </p>
      <h4 className="jet-listing-dynamic-field__content">YouTube</h4>
      <p>
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
        ein Tochterunternehmen der Google LLC, 1600 Amphitheatre Parkway,
        Mountain View, CA 94043 USA
      </p>
      <p>
        Datenschutzerklärung:
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://policies.google.com/privacy
        </a>
      </p>`,
    impressumHTML: `<font size="5">
        <b>Impressum</b>
      </font>
      <br />
      <br />
      Inhaltlich verantwortlich gemäß § 5 TMG:
      <br />
      <br />
      Wolfgang Schenk
      <br />
      Steigerwaldstrasse 1<br />
      70469 Stuttgart
      <br />
      Telefon +49 711 -50459133
      <br />
      E-Mail: wg.schenk@yahoo.com
      <br />
      Finanzamt Stuttgart, Steuer-Nummer 97101/04822
      <br />
      <br />
      Alle „Hardt Stompers“ Logos: Günter Friedhelm
      <br />
      <br /> Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keine
      Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten
      Seiten sind nur deren Betreiber verantwortlich. Datenschutzerklärung nach
      der DSGVO: Die bei der Nutzung dieses Webangebots anfallenden
      Nutzungsdaten nach Telemediengesetz (§ 15 Absatz 1 TMG) werden nur
      verwendet, um das Angebot zu erbringen. Es werden keine IP-Adressen. Ich
      verwende keine Cookies oder andere Tracking-Methoden, die Nutzung dieses
      Angebots auszuwerten. Die Suche auf meiner Seite durchsucht nur mein
      Webangebot. Dabei werden keine personenbezogenen Daten protokolliert.
      Sollten Sie mit mir per E-Mail Kontakt aufnehmen, so verwende ich Ihre
      E-Mail-Adresse nur, um mit Ihnen in Kontakt zu treten. Andere Verwendungen
      schließe ich aus.Ich beabsichtige in keiner Weise Rechte Dritter zu
      verletzen - weder vorsätzlich noch fahrlässig. Bei Streitigkeiten und/oder
      wettbewerbsrechtlichen oder ähnlichen Problemen bitte ich Sie zur
      Vermeidung unnötiger Rechtsstreitigkeiten und den damit verbundenen
      beiderseitigen Kosten mit mir sofort Kontakt aufzunehmen. Ihr Einwand wird
      unverzüglich geprüft, die reklamierten Dinge bzw. Probleme, sofern
      gerechtfertigt, werden abgestellt werden. Eine Kostennote einer
      anwaltlichen Abmahnung ohne vorherige Kontaktaufnahme mit mir werde ich
      deshalb im Sinne einer Schadensminderungspflicht Ihrerseits als
      unbegründet zurückweisen.
      <h3>Haftung für Inhalte</h3>
      Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt. Für die
      Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich jedoch
      keine Gewähr übernehmen. Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG
      für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
      verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieter jedoch
      nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
      überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
      Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
      unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
      von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
      entfernen.
      <h3>Haftung für Links</h3>
      Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werde ich derartige Links umgehend entfernen.
      <h3>Urheberrecht</h3>
      Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
      Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
      aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte umgehend
      entfernen.`,
    phone: "Telefon",
    message: "Nachricht",
    helloText1HTML: `Die im Jahr 1980 in Reutlingen gegründete Lehrerband blickt auf
    mittlerweile stolze YEAR Jahre
    Bühnenpräsenz zurück. In all den Jahren sind wir trotz aller Wechsel
    der Bandbesetzung einem Leitmotiv immer treu geblieben: Unserer
    unbändigen Freude am „Traditional Jazz“, dargebracht in der
    klassischen Besetzung mit Klarinette, Posaune, Kornett, Schlagzeug,
    Banjo und Sousaphon.`,
    helloText2HTML: `Und dennoch erlauben wir uns neben den Jazzklassikern auch Stücke im
  karibischen Sound, Standards mit deutschen Texten unterlegt, sowie eine
  spaßige Simulation im Schellackplattensound zu Gehör zu bringen. Vom
  ersten bis zum letzten Ton servieren wir unsere „Gute-Laune-Musik“, als
  lebensfrohes, kurzweilig buntes und spontan gestaltetes musikalisches
  Entertainment.`,
    kontaktText1HTML: ` <font size="5">
    <b>Die HARDT STOMPERS kommen zu Ihnen!</b>
  </font>
  <br />
  <br />
  Zum klassischen Jazzkonzert, zu Firmenevents, im Biergarten, zur
  Geburtstagsparty,
  <br />
  als Marching Band, beim Jazzfestival, zur Isarfloßfahrt, zum Früh- oder
  Spätschoppen,
  <br />
  im Jazzclub, zum Oldtimertreffen, verstärkt oder unplugged, stationär
  oder ambulant,
  <br />
  und, und, und… <br />
  <br />
  Fragen Sie uns an, wir finden bestimmt eine Lösung für ein Gastspiel.
  <br />
  Schriftlich sowie telefonisch stehen wir gerne Rede und Antwort.`,
    kontaktText2HTML: `Hier`,
    kontaktText3HTML: ` erhalten Sie alle notwendigen Informations-, Werbe- und Presseunterlagen.`,
    kontaktText4HTML: `Wir freuen uns über Ihr Interesse!`,
    schoeneckerTextHTML: `<b>Hardt Stompers fahren auf ihrem Dreamboat zurück in die Zukunft</b><br/><br/>
      „Zurück
          in die Zukunft" ist der aufmerksame Hörer geneigt zu sagen, wenn er die
          mit viel Liebe zum Detail im Tübinger NeckarSound-Studio anlässlich des
          40jährigen Bandjubiläums produzierte neue CD der „Hardt Stompers"
          ausgepackt und aufgelegt hat. Unweigerlich beginnen die Füße
          mitzuwippen, das Mississippi-Steamboat auf dem CD-Cover scheint sich in
          Bewegung zu setzen und als das „Dreamboat" aus dem Titelsong „When My
          Dreamboat Comes Home" ganz ohne Energieprobleme in die ferne
          Vergangenheit der nordamerikanischen Südstaaten zu dampfen.<br/><br/> In der
          Originalbesetzung der Combos aus der New Orleans Ara des frühen 20.
          Jahrhunderts zelebrieren die Hardt Stompers ihren Traditional Jazz. Drei
          Melodieinstrumente, Klarinette, Kornett und Posaune, dazu die Backline
          aus Banjo oder Gitarre, Tuba oder Kontrabass und einem stilsicher
          groovenden Schlagzeug machen gehörig Dampf und führen die Reise mit
          „He's A Son Of The South" tief ins Dixieland, in die Baumwollfelder der
          kreolisch geprägten Südstaaten. In typischer Arbeitsteilung übernimmt
          die Trompete die eingängige Melodie die von der Klarinette umspielt und
          immer wieder mit virtuosen Läufen durchkreuzt wird während die Posaune
          gefühlvoll die Harmonien komplettiert. Ungekünstelter, oft auch
          mehrstimmiger Gesang, selbstverständlich ohne neumodische elektronische
          Effekte, dafür aber gelegentlich im Reibeisen-Sound eines Louis
          Armstrong, stellt die Themen der Stücke vor, über die dann im bunten
          Wechsel improvisiert wird. Mit „Mama Inés" öffnet sich der stilistische
          Horizont in karibische Gefilde bevor überraschend der Zeitsprung zurück
          in die Zukunft, in unsere Gegenwart gelingt. Auf die Melodie und ein
          traditionelles Arrangement von „Alexanders Ragtime Band" erklingt ganz
          zeitgemäß ein neuer Text. Humorvoll und mit kräftigem Augenzwinkern
          bekennen die sechs gestandenen Mannsbilder ganz ungeniert und
          zeitkritisch: „Ja ohne App, da bist' der Depp".<br/><br/>Es folgt ein
          Parforceritt durch die Jazzstile der ersten Hälfte des letzten
          Jahrhunderts. Unterhaltsam und geschmackvoll aufbereitet, von diversen
          Soloeinlagen und höchst professionellen Improvisationen besonders von
          der Klarinette durchzogen, erklingen bekannte Nummern aus den
          unterschiedlichsten Genres der Jazz-, Tanz- und Unterhaltungsmusik.
          Vaudeville, Ragtime, Blues, Dixieland, Boogie Woogie, Swing, Klezmer, ja
          sogar deutschsprachige Marschlieder und Schlager der 20er Jahre wie „Ich
          hab' mein Herz in Heidelberg verloren" ergeben ein buntes Kaleidoskop
          kurzweiliger Wohlfühlmusik, die aus tiefstem Herzen kommt. Selbst auf
          der CD scheint die Spielfreude der Hard Stompers mit Händen greifbar.
          Live gespielt dürfte dieses Programm der Traditionsband aus Deutschlands
          wildem Süden der guten Laune Tür und Tor öffnen, die Stimmung in jedem
          Saal hochkochen lassen.<br/><br/>Gleichzeitig dürfte die Bekanntschaft mit den
          ganz großen Komponisten und Interpreten des Jazz wie Fats Domino, Louis
          Armstrong, Alfredo Rodriguez, Irving Berlin, Sidney Bechet, Kid Ory, Al
          Jolson und vielen anderen dem geneigten Hörer einen vertieften Einblick
          in die Historie des Jazz als elementares Ausdrucksmittel einer
          freiheitlichen Gesellschaft geben. Innerhalb gewisser Leitplanken ist so
          ziemlich alles erlaubt, was gefällt. Und das was nicht gefällt, darf man
          im Jazz getrost einfach weglassen.<br/><br/>Bei aller Vielfalt und stilistischen
          Breite haben die Hardt Stompers in ihrer langen Bandgeschichte einen
          ganz eigenen jazzigen Stil gefunden und mit gleichermaßen ästhetisierten
          und individualisierten, frisch aufpolierten Instrumentalklängen
          versehen. Sie bleiben nah am Original und aktualisieren dieses doch auf
          originelle Weise ganz zeitgemäß, versehen es mit humorigen Texten,
          bieten es in einer liebevollen Inszenierung frisch, fröhlich und
          mitreißend dar. Sie bieten Jazz in seiner unterhaltsamsten Form ohne auf
          manche avantgardistische Irrungen und Wirrungen einzugehen. Ganz ohne
          Berührungsängste holen sie dabei auch manches Denkmal von seinem Sockel,
          hauchen ihm neues Leben ein und machen die Tradition damit fruchtbar für
          die Gegenwart und Zukunft. That's Jazz at its best.<br/><br/>Dr. Helmut Schönecker`,
    cd4DescriptionTextHTML: `Wir dürfen Ihnen stolz die aktuellste CD anlässlich
            <br /> <b>40 Jahre HARDT STOMPERS</b> präsentieren.
            <br />
            <br /> - 1 Stunde & 10 Minuten voller neuer Werke
            <br /> - 70 Minuten musikalische Lebensfreude pur
            <br /> - 4200 Sekunden Traditonal Jazz und mehr
            `,
    cdPromoTextHTML: `<b>Die Tonträger können bei unserem Wolfang
            erworben werden: <a href="mailto:wg.schenk@yahoo.com">E-Mail senden</a> </b>`,
  },
  URLS: {
    facebook: "https://www.facebook.com/HardtStompers",
    youtube: "https://www.youtube.com/@hardtstompers5073",
  },
  VERANSTALTERDOCUMENTS: [
    {
      name: "Pressebild 1",
      thumbnail: Pressebild1_small,
      doc: Pressebild1,
      text: "Copyright: Benjamin Reiner",
      type: "image",
    },
    {
      name: "Pressebild 2",
      thumbnail: Pressebild2_small,
      doc: Pressebild2,
      text: "Copyright: Benjamin Reiner",
      type: "image",
    },
    {
      name: "Plakatvordruck farbig",
      thumbnail: Plakatvordruck_small,
      doc: Plakatvordruck,
      text: null,
      type: "image",
    },
    {
      name: "Plakatvordruck schwarz/weiß",
      thumbnail: PlakatvordruckSW_small,
      doc: PlakatvordruckSW,
      text: null,
      type: "image",
    },
    {
      name: "Logo",
      thumbnail: Logo_small,
      doc: Logo,
      text: null,
      type: "image",
    },
    {
      name: "Pressetext für Konzertankündigungen",
      thumbnail: DocumentIcon,
      doc: Pressetext,
      text: null,
      type: "pdf",
    },
    {
      name: "Technischer Bühnenplan / Technical Rider",
      thumbnail: DocumentIcon,
      doc: TechnicalRider,
      text: null,
      type: "pdf",
    },
  ],
};

RESOURCES.IMAGES.galleryImages = [
  {
    imageSmall: Pressebild1_small,
    imageOriginal: Pressebild1,
    copyright: "Benny Reiner",
  },
  {
    imageSmall: Pressebild2_small,
    imageOriginal: Pressebild2,
    copyright: "Benny Reiner",
  },
  {
    imageSmall: Galerie_Marbach2024_1,
    imageOriginal: Galerie_Marbach2024_1,
    copyright: "Heike Schmit-Scheub",
  },
  {
    imageSmall: Galerie_Marbach2024_2,
    imageOriginal: Galerie_Marbach2024_2,
    copyright: "Heike Schmit-Scheub",
  },
];

export default RESOURCES;
