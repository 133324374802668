//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useAdmin from "../../../hooks/useAdmin";

//& component imports =========================================================================
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

//& component =================================================================================
const GigTypeSelect = ({ className, disabled, gig, mode }) => {
  const { GIGS } = useAdmin();

  //& rendering ===============================================================================
  return (
    <div className="gigArea-gig-input gigArea-gig-input-type">
      <FormControl fullWidth disabled={disabled}>
        <InputLabel id="demo-simple-select-label">Auftritts-Typ</InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={mode === "view" ? gig.type : GIGS.gigData.type}
          label="Filter auswählen"
          onChange={(_event) => {
            GIGS.handleGigDataChange("type", _event.target.value);
          }}
        >
          {GIGS.types.map((_type, _index) => (
            <MenuItem key={_index} value={_type}>
              {_type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default GigTypeSelect;
