const monthNumberToName = (_monthNumber) => {
  const monthNumber = _monthNumber - 1;
  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];
  return monthNames[monthNumber];
};

const convertDate = (_date) => {
  const tmpDate = new Date(_date);

  const day = tmpDate.getDate();
  const dayStr = day < 10 ? `0${day}` : day;

  const month = tmpDate.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month;

  return `${dayStr}.${monthStr}.${tmpDate.getFullYear()}`;
};

const convertDayName = (_name) => {
  switch (_name) {
    case "Montag":
      return "Mo";
    case "Dienstag":
      return "Di";
    case "Mittwoch":
      return "Mi";
    case "Donnerstag":
      return "Do";
    case "Freitag":
      return "Fr";
    case "Samstag":
      return "Sa";
    case "Sonntag":
      return "So";
    case "Mo":
      return "Montag";
    case "Di":
      return "Dienstag";
    case "Mi":
      return "Mittwoch";
    case "Do":
      return "Donnerstag";
    case "Fr":
      return "Freitag";
    case "Sa":
      return "Samstag";
    case "So":
      return "Sonntag";
    default:
      return _name;
  }
};

const dateISOtoLocaleString = (_dateIso) => {
  const tmp = new Date(_dateIso).toLocaleDateString();

  // add leading zero to day and month
  const tmpArr = tmp.split(".");
  const day = tmpArr[0].length === 1 ? `0${tmpArr[0]}` : tmpArr[0];
  const month = tmpArr[1].length === 1 ? `0${tmpArr[1]}` : tmpArr[1];

  return `${day}.${month}.${tmpArr[2]}`;
};

export {
  convertDate,
  convertDayName,
  dateISOtoLocaleString,
  monthNumberToName,
};
