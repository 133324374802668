//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../../hooks/useFrontend.js";

//& logic imports =============================================================================
import { convertDate } from "../../../logic/jsHelper.js";

//& component =================================================================================
const NextGigsMobile = () => {
  const { GIGS } = useFrontend();

  //& rendering ===============================================================================
  if (GIGS.frontendGigs.length === 0) return null;

  return (
    <div className="bubble flexColumn gapSmall textCenter">
      <div className="textHeader mobile-nextGigs-header">
        Unsere nächsten Auftritte
      </div>

      <div className="flexColumn gapSmall mobile-nextGigs-wrapper">
        {GIGS.frontendGigs.map((gig, index) => (
          <div key={index} className="flexColumn mobile-nextGigs-gig">
            {index !== 0 && <div className="mobile-divider" />}
            <div className="mobile-nextGigs-gig-title">
              <b>{gig.title}</b>
            </div>
            <div>{`${convertDate(gig.date)}, ${gig.startTime}-${
              gig.endTime
            }`}</div>
            {gig.anfahrt && (
              <a href={gig.anfahrt} target="_blank" rel="noreferrer">
                Anfahrt
              </a>
            )}
            {gig.info && (
              <a href={gig.info} target="_blank" rel="noreferrer">
                Info
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NextGigsMobile;
