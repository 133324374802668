//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const Datenschutzerklaerung = () => {
  const { RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div
      className="bubble contentWidth"
      dangerouslySetInnerHTML={{
        __html: RESOURCES.TEXTS.datenschutzerklaerungHTML,
      }}
    />
  );
};

export default Datenschutzerklaerung;
