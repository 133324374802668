//& react imports =============================================================================
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

//& library imports ===========================================================================
import axios from "axios";

//& logic imports =============================================================================
import props from "../logic/props";

const AuthContext = createContext();

//& context provider ==========================================================================
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  //& states ==================================================================================
  const [token, tokenSet] = useState(localStorage.getItem("hstd_op"));
  const [loginError, loginErrorSet] = useState(null);
  const [loginData, loginDataSet] = useState({
    email: "",
    password: "",
  });

  //& functions ===============================================================================
  const checkAuth = () => {
    const savedToken = localStorage.getItem("hstd_op");

    if (!savedToken) {
      clearUserData();
      navigate("/login");
      return;
    }

    axios
      .get(`${props.server.url}/confirmJWT`, {
        headers: {
          Authorization: `Bearer ${savedToken}`,
        },
      })
      .then((_response) => {
        if (_response.data === "unauthorized") {
          clearUserData();
          navigate("/login");
        }
      })
      .catch((_error) => {
        clearUserData();
        navigate("/login");
      });
  };

  const clearUserData = () => {
    tokenSet("");
    localStorage.removeItem("hstd_op");
  };

  const handleLoginInput = (_event) => {
    const { name, value } = _event.target;

    loginDataSet((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLoginSubmit = (_event) => {
    loginErrorSet(null);

    _event.preventDefault();

    if (loginData.email !== "" && loginData.password !== "") {
      login(loginData);
      return;
    }

    loginErrorSet("Zugangsdaten nicht erkannt");
  };

  const login = async (data) => {
    axios
      .get(`${props.server.url}/login`, {
        params: {
          email: data.email,
          password: data.password,
        },
      })
      .then((_response) => {
        if (!_response.data.username || !_response.data.token) {
          loginErrorSet("Fehler A10001");
          return;
        }

        tokenSet(_response.data.token);
        localStorage.setItem("hstd_op", _response.data.token);
        navigate("/hsop");
      })
      .catch((_error) => {
        if (_error && _error.response && _error.response.status === 401) {
          loginErrorSet("Zugangsdaten nicht erkannt");
        } else if (
          _error &&
          _error.response &&
          _error.response.status === 429
        ) {
          loginErrorSet("Zu viele Versuche. Bitte warten.");
        }
      });
  };

  const logout = (_destination = "/") => {
    clearUserData();
    navigate(_destination);
  };

  //& rendering ===============================================================================
  return (
    <AuthContext.Provider
      value={{
        checkAuth,
        handleLoginInput,
        handleLoginSubmit,
        login,
        loginData,
        loginDataSet,
        loginError,
        loginErrorSet,
        logout,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
