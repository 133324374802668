//& react & mui imports =======================================================================
import React from "react"

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& component =================================================================================
const DatenschutzerklaerungMobile = () => {
  const { RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="bubble" dangerouslySetInnerHTML={{__html: RESOURCES.TEXTS.datenschutzerklaerungHTML}} />
  )
};

export default DatenschutzerklaerungMobile;
