//& react & mui imports =======================================================================
import React from "react";
import Alert from "@mui/material/Alert";

//& hook imports ==============================================================================
import useAdmin from "../../hooks/useAdmin.js";

//& component =================================================================================
const ErrorAlert = ({ severity = "error" }) => {
  const { BASE } = useAdmin();

  //& rendering ===============================================================================
  return (
    <Alert severity={severity} className="errorAlert">
      {BASE.error}
    </Alert>
  );
};

export default ErrorAlert;
