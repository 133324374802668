//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import HelloText from "../News/HelloText.jsx";
import NextGigs from "../News/NextGigs.jsx";
import NewsEntries from "../News/NewsEntries.jsx";

//& component =================================================================================
const News = () => {
  //& rendering ===============================================================================
  return (
    <>
      <HelloText />
      <NextGigs />
      <NewsEntries />
    </>
  );
};

export default News;
