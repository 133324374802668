//& react & mui imports =======================================================================
import React from "react";

//& component imports =========================================================================
import MenuItem from "./Menu/MenuItem.jsx";

//& component =================================================================================
const Footer = () => {
  //& rendering ===============================================================================
  return (
    <div className="footer">
      <div className="flexRow gapMedium footer-content">
        <MenuItem label="Impressum" code="impressum" />
        <MenuItem label="Datenschutzerklärung" code="datenschutzerklaerung" />
      </div>
    </div>
  );
};

export default Footer;
