//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../hooks/useFrontend.js";

//& component imports =========================================================================
import Band from "./Band/Band.jsx";
import Veranstalter from "./Veranstalter/Veranstalter.jsx";
import Kontakt from "./Kontakt/Kontakt.jsx";
import Start from "./Start/Start.jsx";
import Impressum from "./Legal/Impressum.jsx";
import Galerie from "./Galerie/Galerie.jsx";
import Diskografie from "./Diskografie/Diskografie.jsx";
import Datenschutzerklaerung from "./Legal/Datenschutzerklaerung.jsx";
import ImageModal from "./Modals/ImageModal.jsx";
import TextModal from "./Modals/TextModal.jsx";

//& component =================================================================================
const Content = () => {
  const { MENU, BASE } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexColumn alignCenter content gapSmall">
      {BASE.modalData && BASE.modalData.type === "image" && <ImageModal />}
      {BASE.modalData && BASE.modalData.type === "text" && <TextModal />}
      {MENU.activeMenuItem === "start" && <Start />}
      {MENU.activeMenuItem === "band" && <Band />}
      {MENU.activeMenuItem === "galerie" && <Galerie />}
      {MENU.activeMenuItem === "diskografie" && <Diskografie />}
      {MENU.activeMenuItem === "veranstalter" && <Veranstalter />}
      {MENU.activeMenuItem === "kontakt" && <Kontakt />}
      {MENU.activeMenuItem === "impressum" && <Impressum />}
      {MENU.activeMenuItem === "datenschutzerklaerung" && (
        <Datenschutzerklaerung />
      )}
    </div>
  );
};

export default Content;
