//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../hooks/useFrontend";

//& component imports =========================================================================
import BaseSocialMedia from "./BaseControls/BaseSocialMedia";

//& component =================================================================================
const SocialMedia = ({ elementSize }) => {
  const { RESOURCES } = useFrontend();

  //& rendering ===============================================================================
  return (
    <div className="flexRow gapSmall socialicons">
      <BaseSocialMedia
        elementSize={elementSize}
        network="facebook"
        url={RESOURCES.URLS.facebook}
      />
      <BaseSocialMedia
        elementSize={elementSize}
        network="youtube"
        url={RESOURCES.URLS.youtube}
      />
    </div>
  );
};

export default SocialMedia;
