//& react & mui imports =======================================================================
import React from "react";

//& hook imports ==============================================================================
import useFrontend from "../../hooks/useFrontend.js";

//& logic imports =============================================================================
import { convertDate } from "../../logic/jsHelper.js";

//& component =================================================================================
const NextGigs = () => {
  const { GIGS } = useFrontend();

  //& rendering ===============================================================================
  if (GIGS.frontendGigs.length === 0) return null;

  return (
    <div className="bubble flexColumn gapSmall textCenter gigs contentWidth">
      <div className="textHeader">Unsere nächsten Auftritte</div>
      <div className="gig-table">
        {GIGS.frontendGigs.map((gig, index) => (
          <div key={index} className="flexRow alignCenter gig">
            <div className="gig-property gig-date">{convertDate(gig.date)}</div>
            <div className="gig-property gig-timeslot">
              {gig.startTime} - {gig.endTime}
            </div>
            <div className="gig-property gig-name">{gig.title}</div>
            <div className="gig-property gig-anfahrt">
              {gig.anfahrt && (
                <a href={gig.anfahrt} target="_blank" rel="noreferrer">
                  Anfahrt
                </a>
              )}
            </div>
            <div className="gig-property gig-info">
              {gig.info && (
                <a href={gig.info} target="_blank" rel="noreferrer">
                  Info
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NextGigs;
