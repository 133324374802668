//& react & mui imports =======================================================================
import React, { useCallback, useMemo } from "react";
import { createEditor, Transforms } from "slate";
import {
  Slate,
  Editable,
  withReact,
  useSelected,
  useFocused,
} from "slate-react";
import isUrl from "is-url";
import imageExtensions from "image-extensions";
import { css } from "@emotion/css";

//& hook imports ==============================================================================
import useFrontend from "../../../hooks/useFrontend";

//& logic imports =============================================================================
import { convertDate } from "../../../logic/jsHelper";

//& component =================================================================================
const NewsEntryMobile = ({ entry }) => {
  const { BASE } = useFrontend();

  const ImageElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const focused = useFocused();
    return (
      <div {...attributes}>
        <div contentEditable={false}>
          <img
            src={element.url}
            alt="ads"
            className={css`
              display: block;
              max-width: 100%;
              max-height: 5rem;
              box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
              cursor: pointer;
            `}
            onClick={() => {
              BASE.onImageClick(element.url);
            }}
          />
        </div>
        {children}
      </div>
    );
  };

  const Element = (props) => {
    const { attributes, children, element } = props;
    const style = { textAlign: element.align };
    switch (element.type) {
      case "block-quote":
        return (
          <blockquote
            style={style}
            {...attributes}
            className='before:content-["❝"] before:pr-1 before:text-2xl before:font-semibold after:content-["❞"] after:pl-1 after:text-2xl after:font-semibold'
          >
            <span className="bg-gray-100 p-2 rounded">{children}</span>
          </blockquote>
        );
      case "bulleted-list":
        return (
          <ul style={style} {...attributes} className="list-disc">
            {children}
          </ul>
        );
      case "heading-one":
        return (
          <h1 style={style} {...attributes} className="font-extrabold text-3xl">
            {children}
          </h1>
        );
      case "heading-two":
        return (
          <h2 style={style} {...attributes} className="font-semibold text-xl">
            {children}
          </h2>
        );
      case "list-item":
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case "numbered-list":
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      case "image":
        return <ImageElement {...props} />;
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const insertImage = (editor, url) => {
    const text = { text: "" };
    const image = { type: "image", url, children: [text] };
    Transforms.insertNodes(editor, image);
  };

  const isImageUrl = (url) => {
    if (!url) return false;
    if (!isUrl(url)) return false;
    const ext = new URL(url).pathname.split(".").pop();
    return imageExtensions.includes(ext);
  };

  const withImages = (editor) => {
    const { insertData, isVoid } = editor;

    editor.isVoid = (element) => {
      return element.type === "image" ? true : isVoid(element);
    };

    editor.insertData = (data) => {
      const text = data.getData("text/plain");
      const { files } = data;

      if (files && files.length > 0) {
        for (const file of files) {
          const reader = new FileReader();
          const [mime] = file.type.split("/");

          if (mime === "image") {
            reader.addEventListener("load", () => {
              const url = reader.result;
              insertImage(editor, url);
            });

            reader.readAsDataURL(file);
          }
        }
      } else if (isImageUrl(text)) {
        insertImage(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const editor = useMemo(() => withImages(withReact(createEditor())), []);

  //& rendering ===============================================================================
  return (
    <div className="flexColumn gapSmall newsEntry">
      <Slate editor={editor} initialValue={JSON.parse(entry.content)}>
        <div className="textCenter">
          <font size="4">
            <b>{entry.title}</b>
          </font>
        </div>

        <Editable
          className="newsEntry-editor"
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
        />
        <div className="newsEntry-footer">
          <font size="2">
            <i>
              von {entry.autor} am {convertDate(entry.date)}
            </i>
          </font>
        </div>
      </Slate>
    </div>
  );
};

export default NewsEntryMobile;
